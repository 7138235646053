import React, {ReactNode, useEffect} from 'react';
import useShiftDayData from '../hooks/UseShiftDayData';
import {ShiftDayResponse} from '../types/ShiftDayResponse';

type ShiftDayDataContextType = {
	shiftDayResponse: ShiftDayResponse
}
export const ShiftDayDataContext = React.createContext<ShiftDayDataContextType>(null as any)

type ShiftDayDataProviderProps = {
	shiftId: number,
	date: Date,
	children: ReactNode;
}

function ShiftDayDataProvider(props: ShiftDayDataProviderProps) {
	const [shiftDayResponse, get] = useShiftDayData(props.shiftId, props.date);

	useEffect(() => {
		get();
	}, [props.date])

	return (
		<>
			{shiftDayResponse &&
      <ShiftDayDataContext.Provider value={{shiftDayResponse}}>
				{props.children}
      </ShiftDayDataContext.Provider>
			}
		</>
	)
}

export default ShiftDayDataProvider;
