import {AuthContext} from "../contexts/AuthContext";
import {useContext} from "react";

export default function usePermission() {
    const [loginInfo] = useContext(AuthContext);

    const hasPermission = (permission: string) => {
        return (loginInfo.role.permissions.indexOf(permission) !== -1);
    }

    return {
        hasPermission
    };
}
