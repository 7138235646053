import {CalendarUser} from '../../types/CalendarUser';
import {CalendarEvent} from '../../types/CalendarEvent';
import {UserTypes} from '../../../auth/types/UserTypes';
import UserCell from './UserCell';
import DetailCell from './DetailCell';


type CellProps = {
	calendarUser: CalendarUser,
	date: Date;
	events: CalendarEvent[]
}

export default function Cell(props: CellProps) {

	let showDetail = false;
	if (props.calendarUser.user.type === UserTypes.Event
		|| props.calendarUser.user.type === UserTypes.Equipment
		|| props.calendarUser.user.type === UserTypes.Room) {
		showDetail = true;
	}
	if (showDetail) {
		return (
			<DetailCell {...props}/>
		)
	} else {
		return (
			<UserCell {...props}/>
		)
	}
}
