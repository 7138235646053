import React, {Dispatch, ReactNode, SetStateAction, useState} from 'react';
import {Flag} from '../types/Flag';

export type MonthlyCalendarSettings = {
	directLabelMode: boolean,
	directLabelFlag: Flag | null,
	uploading: boolean,
	showCalendarEventLog: boolean
}

type MonthlyCalendarSettingsContextType = [
	settings: MonthlyCalendarSettings,
	setSettings: Dispatch<SetStateAction<MonthlyCalendarSettings>>
]
export const MonthlyCalendarSettingsContext = React.createContext<MonthlyCalendarSettingsContextType>(null as any)

type MonthlyCalendarSettingsProviderProps = {
	defaultSettings: MonthlyCalendarSettings,
	children: ReactNode
}

export default function MonthlyCalendarSettingsProvider(props: MonthlyCalendarSettingsProviderProps) {

	const [settings, setSettings] = useState<MonthlyCalendarSettings>(props.defaultSettings);

	return (
		<MonthlyCalendarSettingsContext.Provider value={[settings, setSettings]}>
			{props.children}
		</MonthlyCalendarSettingsContext.Provider>
	)
}

