import css from './CalendarPage.module.scss';
import {Outlet, useParams} from 'react-router-dom';
import CalendarDataProvider from '../contexts/CalendarDataContext';
import {useMemo} from "react";

export default function CalendarPage() {

    const params = useParams();
    const calendarId = useMemo(() => {
        return parseInt(params.calendarId as string, 10);
    }, [params])
    return (
        <CalendarDataProvider calendarId={calendarId}>
            <Outlet/>
        </CalendarDataProvider>
    )
}
