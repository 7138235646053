import React, {useContext} from 'react';
import css from './DirectLabelNavigation.module.scss'
import {CalendarDataContext} from '../../contexts/CalendarDataContext';
import {Button} from '@mui/material';
import {MonthlyCalendarSettingsContext} from '../../contexts/MonthlyCalendarSettingsContext';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {cloneDeep} from 'lodash';

type DirectLabelNavigationProps = {}

export default function DirectLabelNavigation(props: DirectLabelNavigationProps) {
	const [calendarData] = useContext(CalendarDataContext);
	const [calendarSettings, setCalendarSettings] = useContext(MonthlyCalendarSettingsContext);

	const setFlagCode = (flagCode: string) => {
		const flag = calendarData.flags.find((flag) => {
			return (flag.code === flagCode)
		})
		if (flag) {
			calendarSettings.directLabelFlag = flag;
			setCalendarSettings(cloneDeep(calendarSettings));
		}
	}

	const handleCancel = () => {
		calendarSettings.directLabelFlag = null;
		calendarSettings.directLabelMode = false;
		setCalendarSettings({...calendarSettings});
	}

	if (calendarSettings.directLabelMode) {
		return (
			<div className={`${css.root} ${(calendarSettings.directLabelFlag === null) ? css.blink : ''}`}>
				{(calendarSettings.directLabelFlag !== null) &&
        <>
            <div className={css.selectedValue}>クリックで【{calendarSettings.directLabelFlag.name}】をON/OFF</div>
        </>
				}
				{(calendarSettings.directLabelFlag === null) &&
        <>
            <PlayArrowIcon/>
            <label htmlFor={'#select-quick-label-code'}>ラベルを選択</label>
            <select
                id={'#select-quick-label-code'}
                defaultValue={''}
                onChange={(e) => {
									setFlagCode(e.target.value);
								}}
            >
                <option value={''}>-------</option>
							{calendarData.flags.map((flag) => {
								return (
									<option value={flag.code}
													key={flag.id}
									>{flag.name}</option>
								)
							})}
            </select>
        </>
				}
				<Button variant={'outlined'}
								sx={{
									borderColor: '#fff',
									color: '#fff'
								}}
								onClick={() => {
									handleCancel()
								}}>終了</Button>
			</div>
		)
	} else {
		return null;
	}
}
