import React, {useContext, useEffect, useRef, useState} from 'react';
import css from './Shift.module.scss';
import ReactGridLayout, {Layout} from 'react-grid-layout';
import ShiftDayGridScale from './ShiftDayGridScale'

import {cloneDeep, template} from 'lodash';

import {GridItem} from '../types/GridItem';
import {GridPlaceholder} from '../types/GridPlaceholder';
import {
	adjustMultiBooking, gridItem2Allocation,
	gridMaxBooking, shiftDayData2GridData
} from '../functions/GridItemUtils';
import CreateShiftAllocationModal from './modals/CreateShiftAllocationModal';
import EditOverlay from '../../general/components/EditOverlay';
import useWindowSize from '../../general/hooks/UseWindowSize';
import {ShiftDataContext} from '../context/ShiftDataContext';
import {ShiftEditorContext} from '../context/ShiftEditorContext';
import ShiftDayUsers from './ShiftDayUsers';
import ShiftUserSelectionModal from './modals/ShiftUserSelectionModal';
import uuid from 'react-uuid';
import {ShiftDayDataContext} from '../context/ShiftDayDataContext';
import {format} from 'date-fns';
import {User} from '../../auth/types/User';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import DropdownButton from '../../general/components/DropdownButton';
import {
	Badge,
	Button,
	Drawer,
	Grid,
	List,
	ListItem, ListItemButton, ListItemText, ListSubheader,
	MenuItem,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import HistoryIcon from '@mui/icons-material/History';
import ReplayIcon from '@mui/icons-material/Replay';
import ja from "date-fns/locale/ja";
import ShiftDayRevisions from './ShiftDayRevisions';

type CreateModalProps = {
	srcLayout: Layout | null,
	isShow: boolean
}

export type GridData = {
	items: GridItem[],
	placeholders: GridPlaceholder[],
}

// const ShiftGrid = forwardRef((props: ShiftGridProps, ref) => {
export default function ShiftDayGrid() {

	const {shiftData} = useContext(ShiftDataContext);
	const {shiftDayResponse} = useContext(ShiftDayDataContext);
	const {editor} = useContext(ShiftEditorContext);

	const fullscreen = true;
	const refRoot = useRef<HTMLDivElement | null>(null);
	const [createModalProps, setCreateModalProps] = useState<CreateModalProps>({srcLayout: null, isShow: false});
	const [gridData, setGridData] = useState<GridData>({
		items: [],
		placeholders: []
	});
	const [showHistoryPopup, setShowHistoryPopup] = useState<HTMLElement | null>(null);

	const size = useWindowSize();

	const setGridItems = (items: GridItem[]) => {
		// const baseShiftDayData = cloneDeep(tmpShiftDayData);
		// baseShiftDayData.allocations = gridItem2Allocation(items, baseShiftDayData);
		// setTmpShiftDayData(baseShiftDayData);

		setGridData({
			items,
			placeholders: gridData.placeholders
		})
	}

	// useEffect(() => {
	// 		invalidateGridItems();
	// 	},
	// 	[editMode]
	// )

	// useEffect(() => {
	// 		if (!editMode) {
	// 			// setTmpShiftDayData(cloneDeep(shiftDayData));
	// 		}
	// 	},
	// 	[shiftDayData]
	// )

	useEffect(() => {

			const gridData = shiftDayData2GridData(editor.tmpShiftDayData);
			adjustMultiBooking(gridData.items);
			// console.log(editor.tmpShiftDayData, gridData);
			setGridData(gridData);
		},
		[editor.tmpShiftDayData]
	)
	useEffect(() => {

			if (editor.isEditMode) {
				invalidateGridItems();
			}
		},
		[editor.isEditMode]
	)


	const gridCols = (editor.tmpShiftDayData.template.endMin - editor.tmpShiftDayData.template.startMin) / editor.tmpShiftDayData.template.intervalMin;
	const gridRows = editor.tmpShiftDayData.template.roles.length * gridMaxBooking;

	const containerWidth = (refRoot.current) ? refRoot.current.clientWidth : 0;
	const containerHeight = (refRoot.current) ? refRoot.current.clientHeight : 0;
	const gridHeaderItemWidth = containerWidth * 0.15;
	const gridScaleHeight = containerHeight * 0.03;
	const gridWidth = containerWidth - gridHeaderItemWidth;
	const gridHeight = containerHeight - gridScaleHeight;
	const gridMargin = 0;
	let gridRowHeight = 10;
	if (fullscreen) {
		gridRowHeight = gridHeight / gridRows;
	}
	const gridHeaderItemHeight = gridRowHeight * gridMaxBooking;
	const styleVariable = {
		"--grid-container-width": containerWidth + 'px',
		"--grid-container-height": containerHeight + 'px',
		"--grid-header-item-height": gridHeaderItemHeight + 'px',
		'--grid-header-item-width': gridHeaderItemWidth + 'px',
		"--grid-row-height": gridRowHeight + 'px',
		"--grid-num-cols": gridCols,
		"--grid-num-rows": gridRows,
		"--grid-margin": gridMargin + 'px',
		"--grid-scale-height": gridScaleHeight + 'px',
	}

	const invalidateGridItems = () => {
		const allocations = gridItem2Allocation(gridData.items, editor.tmpShiftDayData);
		const tmpShiftDayData = cloneDeep(editor.tmpShiftDayData);
		// console.log(allocations);
		tmpShiftDayData.allocations = allocations;
		editor.setTmpShiftDayData(tmpShiftDayData);
		// console.log(allocations);
		// setGridItems(gridData.items);
	}


	const onLayoutResizeStop = (layout: Layout[], oldItem: Layout, newItem: Layout, placeholder: Layout, event: MouseEvent, element: HTMLElement) => {
		if (newItem.h !== oldItem.h) {
			newItem.h = oldItem.h;
		}
		let changed = false;
		gridData.items.forEach((gridItem) => {
			if (gridItem.layout.i === newItem.i) {
				gridItem.layout.x = newItem.x;
				gridItem.layout.w = newItem.w;
				gridItem.placeholderId = 0;
				changed = true;
			}
		})
		if (changed) {
			invalidateGridItems();
		}
	};

	/**
	 * マウス座標を元に対象Placeholderを検索
	 * @param event
	 */
	const detectMouseHoverLayout = (event: MouseEvent) => {
		let cursorX = 0;
		let cursorY = 0;
		if (event instanceof TouchEvent) {
			cursorX = event.changedTouches[0].pageX
			cursorY = event.changedTouches[0].pageY;
		} else if (event instanceof MouseEvent) {
			cursorX = event.clientX;
			cursorY = event.clientY;
		} else {
			cursorX = event['clientX'];
			cursorY = event['clientY'];
		}
		const items: NodeListOf<HTMLDivElement> | undefined = refRoot.current?.querySelectorAll('[data-is-frame="1"]');
		let matchedLayout = null;
		if (items) {
			items.forEach((item) => {
				const rect = item.getBoundingClientRect();
				if (cursorX >= rect.x && cursorX <= (rect.x + rect.width)
					&& cursorY >= rect.y && cursorY <= (rect.y + rect.height)) {
					const matchedLayoutKey = item.getAttribute('data-layout-key');
					const matchedPlaceholder = gridData.placeholders.filter((placeholder) => {
						return (placeholder.layout.i === matchedLayoutKey);
					})
					if (matchedPlaceholder.length) {
						matchedLayout = matchedPlaceholder[0].layout;
					}
				}
			})
		}
		return matchedLayout;
	}

	/**
	 * 移動確定時、対象プレースホルダーにサイズを合わせる
	 * @param l
	 * @param oldItem
	 * @param newItem
	 * @param placeholder
	 * @param event
	 * @param element
	 */
	const onDragStop = (l: Layout[], oldItem: Layout, newItem: Layout, placeholder: Layout, event: MouseEvent, element: HTMLElement) => {
		const matchedPlaceholderLayout: Layout | null = detectMouseHoverLayout(event);
		if (matchedPlaceholderLayout === null) {
			for (let i = 0; i < gridData.items.length; i++) {
				if (gridData.items[i].layout.i === newItem.i) {
					gridData.items.splice(i, 1);
					break;
				}
			}
			invalidateGridItems();
		} else {
			gridData.items.forEach((gridItem) => {
				if (gridItem.layout.i === newItem.i) {
					if (!gridItem.placeholderId) {
						gridItem.layout.x = newItem.x;
						gridItem.layout.w = newItem.w;
					} else {
						gridItem.placeholderId = matchedPlaceholderLayout['i'];
						gridItem.layout.x = matchedPlaceholderLayout['x'];
						gridItem.layout.w = matchedPlaceholderLayout['w'];
					}
					gridItem.layout.y = matchedPlaceholderLayout['y'];
					gridItem.layout.h = matchedPlaceholderLayout['h'];
				}
			})
			invalidateGridItems();
		}
	};

	/**
	 * ドラッグ時のプレースホルダー位置を調整
	 * @param layouts
	 * @param oldItem
	 * @param newItem
	 * @param placeholder
	 * @param event
	 * @param element
	 */
	const onLayoutDrag = (layouts: Layout[], oldItem: Layout, newItem: Layout, placeholder: Layout, event: any, element: HTMLElement) => {
		const matchedLayout: Layout | null = detectMouseHoverLayout(event);
		if (matchedLayout === null) {
			placeholder.x = -100;
		} else {
			placeholder.x = matchedLayout['x'];
			placeholder.y = matchedLayout['y'];
			placeholder.w = matchedLayout['w'];
			placeholder.h = matchedLayout['h'];
			// newItem.w = placeholder.w;
		}
	}


	const onPlaceholderClick = (e: any, id: string) => {
		if (editor.isEditMode) {
			let srcLayout: Layout | null = null;
			gridData.placeholders.forEach((item) => {
				if (item.layout.i === id) {
					srcLayout = item.layout;
				}
			})
			if (srcLayout !== null && srcLayout['static']) {
				setCreateModalProps({isShow: true, srcLayout: srcLayout});
			}
		}
	}

	const onModalClose = () => {
		setCreateModalProps({srcLayout: null, isShow: false});
	}

	const onModalCreate = (user: User, srcLayout: Layout | null) => {
		if (srcLayout === null) {

		} else {
			const newLayout: Layout = {...srcLayout};
			newLayout.static = false;
			newLayout.i = uuid();
			const newGridItem: GridItem = {
				user: user,
				placeholderId: parseInt(srcLayout.i, 10),
				name: user.displayName,
				layout: newLayout,
				id: null,
				freeTime: false
			}
			gridData.items.push(newGridItem);
			invalidateGridItems();
			onModalClose();
		}
	}

	const onLayoutChange = (layouts: Layout[]) => {
		const maxY = gridMaxBooking * editor.tmpShiftDayData.template.roles.length - 1;
		let moved = false;
		layouts.forEach((layout) => {
			if (layout.y > maxY) {
				gridData.items.forEach((gridItem) => {
					if (gridItem.layout.i === layout.i) {
						layout.y = gridItem.layout.y;
						moved = true;
					}
				})
			}
		})
		if (moved) {
			setGridItems(gridData.items);
		}
	}


	const renderPlaceholder = () => {
		return gridData.placeholders.map((item, index) => {
			item.layout.static = true;
			return (
				<div className={css.frameItem}
						 data-grid={{...item.layout}}
						 data-layout-key={item.layout.i}
						 data-is-frame={1}
						 key={item.layout.i}
						 onClick={(e) => onPlaceholderClick(e, item.layout.i)}>
					{editor.isEditMode && <div>クリックして追加</div>}
					<span>{item.name}</span>
				</div>
			)
		})
	}

	const renderLayout = () => {
		return gridData.items.map((gridItem: GridItem, index) => {
			gridItem.layout.isResizable = editor.isEditMode
			gridItem.layout.isDraggable = editor.isEditMode
			return (
				<div className={css.item}
						 data-grid={gridItem.layout}
						 key={gridItem.layout.i}
						 data-is-frame={0}
						 data-collision={(gridItem.numCollision)}
						 data-is-draggable={(gridItem.layout.isDraggable) ? 1 : 0}
						 data-sort-id={gridItem.layout.y % gridMaxBooking}
				>{gridItem.layout.isDraggable &&
        <OpenWithIcon/>
				}
					{gridItem.name}
				</div>
			)
		})
	}

	return (
		<>
			<div className={css.root}>
				<EditOverlay onSubmit={editor.submit} onCancel={editor.cancel} show={editor.isEditMode}/>
				<Grid container justifyContent={'center'} spacing={1}>
					<Grid item>
						{editor.isEditMode &&
            <DropdownButton startIcon={<ReplayIcon/>} label={editor.tmpShiftDayData.template.name}
                            variant={'contained'}>
							{shiftData.templates.filter((template) => {
								return (editor.tmpShiftDayData.template.id !== template.id)
							}).map((template) => {
								return <MenuItem key={template.id} onClick={() => {
									editor.onChangeTemplate(template.id)
								}}>{template.name}</MenuItem>
							})}
            </DropdownButton>
						}
						{!editor.isEditMode &&
            <span className={css.templateName}>{editor.tmpShiftDayData.template.name}</span>
						}
					</Grid>
					<Grid item>
						<Button
							disabled={editor.isEditMode}
							variant={'contained'}
							startIcon={<EditIcon/>}
							onClick={() => {
								editor.setEditMode(true)
							}}
						>編集モード</Button>
					</Grid>
					<Grid item>
						<Badge badgeContent={shiftDayResponse.revisions.length} color={'warning'}>
							<Button
								disabled={editor.isEditMode}
								variant={'contained'}
								startIcon={<HistoryIcon/>}
								color={'success'}
								onClick={(e) => {
									setShowHistoryPopup(e.target as HTMLElement);
								}}
							>更新履歴</Button>
						</Badge>
						<ShiftDayRevisions open={Boolean(showHistoryPopup)} onClose={() => {
							setShowHistoryPopup(null)
						}}/>
					</Grid>
				</Grid>
			</div>
			<Grid container sx={{height: '70vh'}} spacing={2}>
				<Grid item xs={10}>
					<div style={{height: '70vh', padding: '10px', position: 'relative'}}>
						<div ref={refRoot} className={css.root} style={styleVariable as React.CSSProperties}>
							<div className={css.background}>
								<ShiftDayGridScale
									startHour={editor.tmpShiftDayData.template.startMin}
									interval={editor.tmpShiftDayData.template.intervalMin}
									size={16}/>
								<div className={css.rowHeaders}>
									{editor.tmpShiftDayData.template.roles.map((position, index) => {
										return (
											<div key={index} className={css.rowHeader}>{position.name}</div>
										)
									})
									}
								</div>
								<ReactGridLayout
									className={css.grid}
									onLayoutChange={onLayoutChange}
									cols={gridCols}
									rowHeight={gridRowHeight}
									width={gridWidth}
									compactType={null}
									autoSize={false}
									allowOverlap={true}
									margin={[gridMargin, gridMargin]}
									onResizeStop={onLayoutResizeStop}
									onDrag={onLayoutDrag}
									onDragStop={onDragStop}
									resizeHandles={(editor.isEditMode) ? ['se'] : []}
									isDroppable={false}
									maxRows={gridRows}
									preventCollision={true}
								>
									{renderPlaceholder()}
									{renderLayout()}
								</ReactGridLayout>

							</div>
							{createModalProps.isShow && <CreateShiftAllocationModal
                  srcLayout={createModalProps.srcLayout}
                  onModalCreate={onModalCreate}
                  onClose={onModalClose}
                  users={editor.tmpShiftDayData.shiftDayUsers}
              />}
						</div>
					</div>
				</Grid>
				<Grid item xs={2}>
					<ShiftDayUsers/>
					{editor.showShiftUserSelectionModal && <ShiftUserSelectionModal/>}
				</Grid>
			</Grid>

		</>
	);

};


