import { Controller} from 'react-hook-form';
import React, {ChangeEvent} from 'react';
import {Checkbox, FormControlLabel} from '@mui/material';


type ReactHookCheckboxProps = {
	name: string,
	control: any,
	label: string,
	disable: boolean,
	checked: boolean,
	onChange?: (e: ChangeEvent<HTMLInputElement>) => void
}

export default function ReactHookCheckbox(props: ReactHookCheckboxProps) {

	return (
		<Controller
			name={props.name}
			control={props.control}
			render={({field}) => (
				<FormControlLabel
					control={<Checkbox
						{...field}
						checked={props.checked}
						onChange={(e) => {
							field.onChange(e);
							if (props.onChange) {
								props.onChange(e);
							}
						}}
					/>}
					label={props.label}
					disabled={props.disable}
					// onChange={(e) => {
					// 	if ((e.target as HTMLInputElement).checked) {
					// 		addEventUser(calendarUser);
					// 	} else {
					// 		removeEventUser(calendarUser);
					// 	}
					// }}
				/>
			)}
		/>
	)
}
