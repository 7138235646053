import React, {useLayoutEffect, useState} from 'react';


export default function useWindowSize(): { width: number, height: number } {
	const [size, setSize] = useState<{ width: number, height: number }>({width: 0, height: 0});
	useLayoutEffect(() => {
		const updateSize = (): void => {
			setSize({width: window.innerWidth, height: window.innerHeight});
		};
		window.addEventListener('resize', updateSize);
		updateSize();
		return () => window.removeEventListener('resize', updateSize);
	}, []);
	return size;
};
