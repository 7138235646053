import {User} from '../types/User';
import {
    Avatar,
    Box,
    Grid,
    Paper,
    TextField,
    Typography
} from '@mui/material';
import {teal} from '@mui/material/colors';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import {LoadingButton} from '@mui/lab';
import {useContext, useState} from 'react';
import Api from '../../general/classes/Api';
import {FlashMessageContext} from '../../general/contexts/FlashMessageContext';
import {ENDPOINTS} from '../../general/constants/ENDPOINTS';
import {UserRole} from "../types/Role";
import {LoginInfo} from "../types/LoginInfo";
import {AuthResponse} from "../types/AuthResponse";


export type LoginPageProps = {
    successLoginHandler: (loginInfo: LoginInfo, token: string) => void
}


export default function LoginPage(props: LoginPageProps) {

    const [loading, setLoading] = useState<boolean>(false);
    const {flashErrorHandler} = useContext(FlashMessageContext);
    const api = Api.getInstance();

    const onSubmit = (e: any) => {
        api.setToken('');
        e.preventDefault();
        const login = e.target.login.value;
        const password = e.target.password.value;
        setLoading(true);

        api.post<AuthResponse>(ENDPOINTS.auth, {login, password}).then((resp) => {
            props.successLoginHandler({
                user: resp.user,
                role: resp.role,
                impersonator: null
            }, resp.bearerToken);
        }).catch(flashErrorHandler).finally(() => {
            setLoading(false);
        });
    }

    return (
        <Grid>
            <Paper
                elevation={2}
                sx={{
                    p: 4,
                    height: "70vh",
                    width: "320px",
                    m: "20px auto"
                }}
            >
                <Grid
                    container
                    direction="column"
                    alignItems="center"
                >
                    <Avatar sx={{bgcolor: teal[400]}}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography variant={"h5"} sx={{m: "30px"}}>
                        ログイン
                    </Typography>
                </Grid>
                <form onSubmit={onSubmit}>
                    <TextField
                        label="職員コード"
                        variant="outlined"
                        name='login'
                        fullWidth
                        required
                        margin={'normal'}
                    />
                    <TextField
                        type="password"
                        label="パスワード"
                        variant="outlined"
                        name='password'
                        margin={'normal'}
                        fullWidth
                        required
                    />
                    <Box mt={8}>
                        <LoadingButton loading={loading} type="submit" color="primary" variant="contained" size="large"
                                       fullWidth>ログイン</LoadingButton>
                    </Box>
                </form>
            </Paper>
        </Grid>
    )

}
