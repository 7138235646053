import css from './ChangerComponent.module.scss';
import {Link} from 'react-router-dom';
import React, {ReactNode} from 'react';
import {IconButton} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export type ChangerComponentProp = {
	title: string,
	prevLinkUrl: string,
	nextLinkUrl: string,
	centerLinkTitle: string,
	centerLinkUrl: string,
	subActionNode?: ReactNode,
	useDatePicker?: boolean
}

export default function ChangerComponent(props: ChangerComponentProp) {




	return (
		<header className={css.header}>
			<div className={css.content}>
				{/*<div className={css.name}></div>*/}
				<div className={css.date}>{props.title}</div>
				<div className={css.navigation}>
					<div className={css.yesterday}><IconButton color={'primary'} component={Link}
																										 to={props.prevLinkUrl}><ArrowBackIosNewIcon/></IconButton></div>
					<div className={css.today}><IconButton color={'primary'} component={Link}
																								 to={props.centerLinkUrl}>{props.centerLinkTitle}</IconButton></div>
					<div className={css.tomorrow}><IconButton color={'primary'} component={Link}
																										to={props.nextLinkUrl}><ArrowForwardIosIcon/></IconButton></div>
				</div>
				<div className={css.dummy}/>
				<div className={css.subActions}>
					{props.subActionNode}
				</div>
			</div>
		</header>
	)
}
