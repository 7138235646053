import {format} from 'date-fns';
import {useContext, useEffect, useState} from 'react';
import {ShiftDayRevision} from '../types/ShiftDayRevision';

import {LaravelEchoContext} from '../../general/contexts/LaravelEchoContext';
import Api from '../../general/classes/Api';
import {ENDPOINTS} from '../../general/constants/ENDPOINTS';
import {ShiftDayResponse} from '../types/ShiftDayResponse';


export default function useShiftDayData(shiftId: number, date: Date) {

	const [shiftDayResponse, setShiftDayResponse] = useState(null as any);
	const laravelEchoContext = useContext(LaravelEchoContext);
	const api = Api.getInstance();

	const get = () => {
		api.get<ShiftDayResponse>(ENDPOINTS.shift + '/' + shiftId + '/day/' + format(date, 'yyyyMMdd')).then(
			(data) => {

				if (data) {
					data.shiftDayData.date = new Date(data.shiftDayData.date);
					if (!data.shiftDayData.template) {
						data.shiftDayData.template = {
							id: 0,
							name: 'null',
							startTime: '0:00',
							endTime: '0:00',
							startMin: 0,
							endMin: 0,
							intervalMin: 30,
							placeholders: [],
							roles: []
						}
					}
					data.revisions.forEach((revision: ShiftDayRevision) => {
						revision.createdAt = new Date(revision.createdAt);
					})
				}
				setShiftDayResponse(data);
			}
		).finally(() => {

		})
	}

	useEffect(() => {
		laravelEchoContext.channel("mychannel").listen(".shift.day.updated", (ev: any) => {
			get();
		});
		return () => {
			laravelEchoContext.channel("mychannel").stopListening(".shift.day.updated");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [shiftId, date])


	// const [response, get, axiosError, unknownError] = useApiGet<ShiftDayResponse>('/shift/' + shiftId + '/day/' + format(date, 'yyyyMMdd'), converter)
	//
	return [shiftDayResponse, get];
}
