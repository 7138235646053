
export class Time {
	hour: number;
	min: number;
	sec: number;

	constructor(timeString: string) {
		this.hour = 0;
		this.min = 0;
		this.sec = 0;
		if (timeString) {
			const shortMatches = timeString.match(/^(\d{1,2}):(\d{1,2})$/);
			const longMatches = timeString.match(/^(\d{1,2}):(\d{1,2}):(\d{1,2})$/);
			if (shortMatches) {
				this.hour = parseInt(shortMatches[1], 10);
				this.min = parseInt(shortMatches[2], 10);
			}
			if (longMatches) {
				this.hour = parseInt(longMatches[1], 10);
				this.min = parseInt(longMatches[2], 10);
				this.sec = parseInt(longMatches[3], 10);
			}
		}
	}

	getHour = () => {
		return this.hour;
	}
	getMin = () => {
		return this.min;
	}
	getSec = () => {
		return this.sec;
	}

	toString = () => {
		let hourString = this.hour.toString();
		if (this.hour < 10) {
			hourString = '0' + hourString;
		}
		let minString = this.hour.toString();
		if (this.hour < 10) {
			minString = '0' + minString;
		}
		let secString = this.hour.toString();
		if (this.hour < 10) {
			secString = '0' + secString;
		}
		return hourString + ':' + minString + ':' + secString;
	}
}
