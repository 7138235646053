import {useContext, useRef, useState} from 'react';
import {Button, Divider, ListItemIcon, ListItemText, Menu, MenuItem} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import {MonthlyCalendarSettingsContext} from '../../contexts/MonthlyCalendarSettingsContext';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import LabelIcon from '@mui/icons-material/Label';
import useImpersonate from "../../../auth/hooks/useImpersonate";
import usePermission from "../../../auth/hooks/usePermission";
import {PERMISSION} from "../../../auth/types/PERMISSION";
import useFlash from "../../../general/hooks/UseFlash";

export default function SubActions() {

    const [calendarSettings, setCalendarSettings] = useContext(MonthlyCalendarSettingsContext);
    const {showImpersonateDialog} = useImpersonate();
    const {hasPermission} = usePermission();
    const {flash} = useFlash();


    const buttonRef = useRef(null);
    const [show, setShow] = useState<boolean>(false);
    const handleClose = () => {
        setShow(false);
    }


    const toggleDirectLabelMode = () => {
        calendarSettings.directLabelMode = (!calendarSettings.directLabelMode);
        setCalendarSettings({...calendarSettings});
    }

    const toggleShowLogs = () => {
        calendarSettings.showCalendarEventLog = (!calendarSettings.showCalendarEventLog);
        setCalendarSettings({...calendarSettings});
    }

    const onClickQuickLabel = () => {
        console.log('onClickQuickLabel');
        if (hasPermission(PERMISSION.CalendarAllWrite)) {
            toggleDirectLabelMode();
            handleClose();
        } else if (hasPermission(PERMISSION.UserImpersonateExec)) {
            showImpersonateDialog();
        } else {
            flash.error('権限がありません');
        }

    }

    return (
        <>
            <Button ref={buttonRef} color="primary"
                    size={'large'}
                    variant={'outlined'}
                    onClick={() => {
                        setShow(true)
                    }}
                    sx={{
                        whiteSpace: 'nowrap'
                    }}
                    startIcon={<SettingsIcon/>}
            >その他</Button>
            <Menu
                id="basic-menu"
                anchorEl={buttonRef.current}
                open={show}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={onClickQuickLabel}>
                    <ListItemIcon>
                        <LabelIcon/>
                    </ListItemIcon>
                    <ListItemText
                        id="switch-list-label-direct-mode"
                        primary={'クイックラベル'}
                        secondary={'社頭番・公休等、1クリックでON/OFF'}
                    />
                </MenuItem>
                <Divider/>
                <MenuItem onClick={() => {
                    toggleShowLogs();
                    handleClose();
                }}>
                    <ListItemIcon>
                        <HistoryOutlinedIcon/>
                    </ListItemIcon>
                    <ListItemText
                        id="switch-list-label-show-logs"
                        primary={'変更履歴'}
                    />
                </MenuItem>
            </Menu>
        </>
    )

}
