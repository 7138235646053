import {Controller} from 'react-hook-form';
import React, {ChangeEvent, ReactNode} from 'react';
import {TextField} from '@mui/material';
import {RegisterOptions} from 'react-hook-form/dist/types/validator';

type ReactHookSelectProps = {
	error?: boolean,
	helperText?: string
	name: string,
	control: any,
	children: ReactNode,
	label: string,
	onChange?: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void,
	rules?: RegisterOptions<any, any>
}

export default function ReactHookSelect(props: ReactHookSelectProps) {

	return (
		<Controller
			name={props.name}
			control={props.control}
			rules={props.rules}
			render={({field}) => (
				<TextField
					error={!!(props.error)}
					{...field}
					select
					sx={{mt: 2}}
					required
					fullWidth
					label={props.label}
					onChange={(e) => {
						field.onChange(e);
						if (props.onChange) {
							props.onChange(e);
						}
					}}
					helperText={props.helperText}
				>
					{props.children}
				</TextField>
			)}
		/>
	)
}
