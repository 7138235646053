import {
	Collapse,
	Divider,
	Drawer, Grid, IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	ListSubheader, Pagination, Toolbar, Typography, useMediaQuery
} from '@mui/material';
import {format} from 'date-fns';
import ja from 'date-fns/locale/ja';
import React, {ReactNode, useContext, useEffect, useState} from 'react';
import {MonthlyCalendarSettingsContext} from '../../contexts/MonthlyCalendarSettingsContext';
import useCalendarLog from '../../hooks/UseCalendarLog';
import SpinnerContainer from '../../../general/components/SpinnerContainer';
import css from './CalendarEventLogs.module.scss'
import {User} from '../../../auth/types/User';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CloseIcon from '@mui/icons-material/Close';
import {CalendarEvent} from '../../types/CalendarEvent';

type CalendarEventLogsProps = {
	calendarId: number,
	startDate: Date,
	endDate: Date,
}

export default function CalendarEventLogs(props: CalendarEventLogsProps) {

	const [calendarSettings, setCalendarSettings] = useContext(MonthlyCalendarSettingsContext);
	const [calendarEventLogResponse, getCalendarEventLog, loading] = useCalendarLog();
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [openLogId, setOpenLogId] = useState<number>(0);
	const isMobileDevice = useMediaQuery('(max-width:500px)');

	const numItemsPerPage = 20;
	const numTotalCount: string = (calendarEventLogResponse) ? '(' + calendarEventLogResponse.numEventLogs.toString() + '件)' : '';

	let numPages = 0;
	if (calendarEventLogResponse) {
		numPages = Math.ceil(calendarEventLogResponse.numEventLogs / numItemsPerPage);
	}


	const onClose = () => {
		const newSettings = {...calendarSettings};
		newSettings.showCalendarEventLog = false;
		setCalendarSettings(newSettings);
	}


	const findUser = (userId: number): User | undefined => {
		if (calendarEventLogResponse) {
			return calendarEventLogResponse.users.find((user) => {
				return (userId === user.id);
			});
		}
		return undefined;
	}

	const renderPagination = () => {
		if (calendarEventLogResponse) {
			return (
				<Pagination
					color="primary"
					shape="rounded"
					size={(isMobileDevice) ? 'small' : 'medium'}
					count={numPages}
					sx={{
						padding: ".5em .5em"
					}}
					page={currentPage}
					onChange={handlePageChange}
					siblingCount={1} boundaryCount={1}
					classes={{
						ul: css.paginationUl
					}}
					hideNextButton={false}
					hidePrevButton={false}
				/>
			)
		} else {
			return null;
		}
	}

	const renderFlagEvent = (event: CalendarEvent) => {
		if (!event.flag) {
			return null;
		}
		return (
			<div className={css.event}>
				<div className={css.eventDate}>{format(event.startDate, 'M月d日(E)', {locale: ja})}</div>
				<div className={css.eventFlagName}>{event.flag.name}</div>
				<div className={css.eventAttendees}>
					{
						event.eventUsers.map((eventUser) => {
							const user = findUser(eventUser.userId);
							if (user) {
								return (
									<span className={css.eventAttendee}>{user.displayName}</span>
								)
							} else {
								return null;
							}
						})
					}
				</div>
			</div>
		)

	}
	const renderNormalEvent = (event: CalendarEvent) => {
		const startDateString = format(event.startDate, 'M月d日(E) H:mm', {locale: ja});
		let endDateString = '';
		if (
			event.startDate.getFullYear() === event.endDate.getFullYear()
			&& event.startDate.getMonth() === event.endDate.getMonth()
			&& event.startDate.getDate() === event.endDate.getDate()
		) {
			endDateString = format(event.endDate, 'H:mm', {locale: ja});
		} else {
			endDateString = format(event.endDate, 'M月d日(E) H:mm', {locale: ja});
		}
		return (
			<div className={css.event}>
				<div className={css.eventDate}>{startDateString} - {endDateString}</div>
				<div className={css.eventSubject}>{event.subject}</div>
				<div className={css.eventAttendees}>
					{
						event.eventUsers.map((eventUser) => {
							const user = findUser(eventUser.userId);
							if (user) {
								return (
									<span className={css.eventAttendee}>{user.displayName}</span>
								)
							} else {
								return null;
							}
						})
					}
				</div>
			</div>
		)
	}

	const renderEvent = (event: CalendarEvent) => {
		if (event.flag) {
			return renderFlagEvent(event);
		} else {
			return renderNormalEvent(event);
		}
	}

	const renderLogs = () => {
		if (calendarEventLogResponse && calendarEventLogResponse.eventLogs.length === 0) {
			return (
				<ListSubheader>該当履歴は存在しません</ListSubheader>
			)
		} else if (calendarEventLogResponse) {
			return (
				<>
					{calendarEventLogResponse.eventLogs.map((log) => {
						const operator = findUser(log.createdBy);
						const operatorName = (operator) ? operator.displayName : '不明なユーザー';
						let eventNode: ReactNode = null;
						if (log.type === 'create') {
							if (log.afterData) {
								eventNode = renderEvent(log.afterData);
							}
						} else if (log.type === 'delete') {
							if (log.beforeData) {
								eventNode = renderEvent(log.beforeData);
							}
						} else if (log.type === 'update') {
							if (log.afterData) {
								eventNode = renderEvent(log.afterData);
							}
						} else {

						}

						let logTypeLabel = '';
						switch (log.type) {
							case 'create':
								logTypeLabel = '作成';
								break;
							case 'delete':
								logTypeLabel = '削除';
								break;
							case 'update':
								logTypeLabel = '更新';
								break;
						}

						//
						return (
							<>
								<ListItem
									key={log.id}
									dense={true}
									classes={{
										root: css.ListItemRoot
									}}
									onClick={() => {
										if (log.id === openLogId) {
											setOpenLogId(0);
										} else {
											setOpenLogId(log.id)
										}
									}}
								>
									<div className={css.ListItemWrap}>
										<ListItemButton classes={{
											root: css.ListItemButtonRoot
										}}>
											<ListItemText classes={{
												root: css.ListItemTextRoot
											}}>
												<div className={css.log}>
													<div className={css.logType} data-type-code={log.type}>{logTypeLabel}</div>
													<div className={css.logDetail}>
														{eventNode}
														<div className={css.logDetailCause}>
															<div className={css.logDetailCauseDate}>{format(log.createdAt, 'yyyy/MM/dd HH:mm')}</div>
															<div className={css.logDetailCauseOperator}>{operatorName}</div>
														</div>
													</div>
													<div className={css.chevron}>
														{log.id === openLogId &&
                            <ExpandLessIcon/>
														}
														{log.id !== openLogId &&
                            <ChevronRightIcon/>
														}
													</div>
												</div>
											</ListItemText>
										</ListItemButton>
									</div>
									<Collapse in={(log.id === openLogId)} timeout="auto" unmountOnExit>
										<div>(ToDo)詳細をここに表示します</div>
									</Collapse>
								</ListItem>
							</>
						)
					})}
				</>
			)

		} else {
			return null;
		}
	}


	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		setCurrentPage(value);
	}

	useEffect(() => {
		if (calendarSettings.showCalendarEventLog) {
			let offset = (currentPage - 1) * numItemsPerPage;
			if (offset < 0) {
				offset = 0;
			}
			getCalendarEventLog(props.calendarId, props.startDate, props.endDate, offset, numItemsPerPage);
		}
	}, [calendarSettings.showCalendarEventLog, currentPage])


	useEffect(() => {
		setCurrentPage(1);
	}, [props.startDate, props.endDate])

	return (
		<>
			<Drawer
				anchor={'right'}
				open={calendarSettings.showCalendarEventLog}
				onClose={onClose}
			>
				<Toolbar>
					<IconButton onClick={onClose}>
						<CloseIcon fontSize={'medium'}/>
					</IconButton>
					<Typography sx={{marginLeft: "1em"}}>
						{format(props.startDate, 'M/d')}〜{format(props.endDate, 'M/d')}に関する変更履歴 {numTotalCount}
					</Typography>
				</Toolbar>
				<Divider/>

				<div className={css.root}>
					<List subheader={
						<>
							<div>(ToDo)ログ絞り込み機能がここに入ります。日付指定、関連人物指定、作成|更新|削除指定</div>
							<ListSubheader>
								{renderPagination()}
							</ListSubheader>
						</>
					}>
						<SpinnerContainer loading={loading} inner={true}>
							{renderLogs()}
						</SpinnerContainer>
					</List>
				</div>
			</Drawer>
			{/*{revision && <ShiftDayRevisionDetail revision={revision} onClose={() => {*/}
			{/*	setRevision(null)*/}
			{/*}}/>}*/}
		</>

	)
}
