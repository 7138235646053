import css from './TableComponent.module.scss'
import {useContext, useMemo, useRef, useState} from 'react';
import {CalendarDataContext} from '../../contexts/CalendarDataContext';
import {EventSearchContext} from '../../contexts/EventSearchContext';
import {EventSearchResult} from '../../hooks/UseEventList';
import UserCell from './UserCell';
import HeaderComponent from './HeaderComponent';
import {MonthlyCalendarSettingsContext} from '../../contexts/MonthlyCalendarSettingsContext';
import CalendarEventLogs from '../Calendar/CalendarEventLogs';
import {Tab, Tabs} from '@mui/material';
import {CalendarUserGroup} from '../../types/CalendarUserGroup';
import {MonthlyData} from '../../classes/ManthlyData';
import Cell from './Cell';
import {UserTypes} from '../../../auth/types/UserTypes';
import {useInView} from 'react-intersection-observer';
import {LayoutContext} from "../../../app/contexts/LayoutContext";


export default function TableComponent() {

    // const overallCalendarUserGroup: CalendarUserGroup = {
    // 	id: 999,
    // 	name: '全体',
    // 	color: '#999',
    // 	userIds: []
    // }

    const [layoutStatus] = useContext(LayoutContext);
    const [calendarData] = useContext(CalendarDataContext);
    const result: EventSearchResult = useContext(EventSearchContext);
    const [calendarSettings] = useContext(MonthlyCalendarSettingsContext);
    const [currentTabId, setCurrentTabId] = useState<number>(0);

    const tabs: CalendarUserGroup[] = calendarData.groups;
    const data = useMemo(() => {
        return new MonthlyData(calendarData, result);
    }, [calendarData, result])

    const eventUser = data.getEventUser();
    let currentGroup = tabs[currentTabId];

    const {ref, inView, entry} = useInView({
        rootMargin: "-" + (layoutStatus.appBarHeight + 1).toString() + "px 0px 0px 0px",
        threshold: [1, 1, 1, 1, 1]
    });

    // console.log(inView);
    // console.log(entry?.boundingClientRect?.x, entry?.boundingClientRect?.y);
    const elem = useRef<HTMLDivElement>(null);

    let theadTop: string = 'inherit';
    if (elem.current) {
        theadTop = (layoutStatus.appBarHeight + elem.current.clientHeight).toString() + 'px';
    }

    return (
        <div className={css.root}>
            <div className={css.headerContainer}>
                <div className={css.headerPlaceholder} ref={ref}
                     style={{height: (elem.current) ? elem.current.clientHeight : 'auto'}}>
                    <div className={css.headerContent} data-in-view={(inView) ? 1 : 0} ref={elem}
                         style={{zIndex: "100", background: "#fff"}}>
                        <HeaderComponent/>
                    </div>
                </div>
                <div className={css.tabContainer}>
                    <Tabs
                        value={currentTabId}
                        onChange={(event: React.SyntheticEvent, newValue: number) => {
                            setCurrentTabId(newValue);
                        }}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                        sx={{
                            marginBottom: '.5em',
                        }}
                        TabScrollButtonProps={{}}
                        allowScrollButtonsMobile
                    >
                        {tabs.map((group, index) => {
                            return (
                                <Tab key={group.id} label={group.name}/>
                            )
                        })}
                    </Tabs>
                </div>

            </div>

            <table className={css.table} data-uploading={(calendarSettings.uploading) ? '1' : ''}>
                <thead style={{top: theadTop}}>
                <tr className={css.tableHeaders}>
                    <th colSpan={2} className={css.headerDummyCell}/>
                    {eventUser &&
                        <th className={css.headerCell} key={eventUser.user.id}><span>{eventUser.user.displayName}</span>
                        </th>
                    }
                    {data.getUsers(currentGroup.id).map((calendarUser) => {
                        let className = css.verticalText + ' ' + css.headerCell;
                        if (calendarUser.user.type === UserTypes.Equipment || calendarUser.user.type === UserTypes.Room) {
                            className = css.headerCell;
                        }
                        const style: React.CSSProperties = {
                            'borderTop': '5px solid ' + calendarUser.user.position.color
                        }
                        return (
                            <th className={className} key={calendarUser.user.id}
                                style={style}><span>{calendarUser.user.displayName}</span></th>
                        )
                    })}
                </tr>
                </thead>
                <tbody>
                <>
                    {data.getDays().map((day) => {
                        return (
                            <tr key={day.date.getTime().toString()}>
                                <td className={css.dayName} data-week-number={day.date.getDay()}>
                                    <span>{day.date.getDate()} {day.weekName}</span></td>
                                <td className={css.rokuyoName}
                                    data-week-number={day.date.getDay()}
                                    data-rokuyo={day.rokuyo}><span>{day.rokuyo}</span></td>
                                {eventUser &&
                                    <Cell
                                        calendarUser={eventUser}
                                        date={day.date}
                                        key={day.date.toISOString() + eventUser.user.id.toString()}
                                        events={data.getEvents(day.date, eventUser.user.id)}/>
                                }
                                {data.getUsers(currentGroup.id).map((calendarUser, index) => {
                                    let events = data.getEvents(day.date, calendarUser.user.id)
                                    if (!events) {
                                        events = [];
                                    }
                                    return (
                                        <Cell
                                            calendarUser={calendarUser}
                                            date={day.date}
                                            key={day.date.toISOString() + calendarUser.user.id.toString()}
                                            events={events}/>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </>
                </tbody>
            </table>
            <CalendarEventLogs calendarId={result.calendarId} startDate={result.start} endDate={result.end}/>
        </div>
    )
}
