import {Button, Menu} from '@mui/material';
import * as React from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

type DropdownButtonProps = {
	label: string,
	variant: 'contained' | 'text' | 'outlined',
	children: React.ReactNode,
	startIcon?: React.ReactNode
}

export default function DropdownButton(props: DropdownButtonProps) {


	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);


	const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleCloseUserMenu = () => {
		setAnchorEl(null);
	};



	return (
		<>
			<Button
				variant={'contained'}
				onClick={handleOpenUserMenu}
				startIcon={props.startIcon}
				endIcon={(Boolean(anchorEl)) ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>}
			>
				{props.label}
			</Button>
			<Menu
				sx={{mt: '45px'}}
				id="menu-appbar"
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				keepMounted
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				open={Boolean(anchorEl)}
				onClose={handleCloseUserMenu}
			>
				{props.children}
			</Menu>
		</>
	)
}
