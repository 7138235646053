import {ShiftData} from '../types/ShiftData';
import React, {ReactNode} from 'react';
import useShiftData from '../hooks/UseShiftData';

type ShiftDataContextType = {
	shiftData: ShiftData
}
export const ShiftDataContext = React.createContext<ShiftDataContextType>(null as any)

type ShiftDataProviderProps = {
	shiftId: number,
	children: ReactNode;
}

function ShiftDataProvider(props: ShiftDataProviderProps) {
	const [shiftData] = useShiftData(props.shiftId);
	return (
		<>
			{shiftData &&
      <ShiftDataContext.Provider value={{shiftData}}>
				{props.children}
      </ShiftDataContext.Provider>
			}
		</>
	)
}

export default ShiftDataProvider;
