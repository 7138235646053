import {CalendarEvent} from '../../types/CalendarEvent';
import {CalendarUser} from '../../types/CalendarUser';
import css from './UserCellDialogEdit.module.scss';
import {format} from 'date-fns';
import {Button, IconButton, Tooltip} from '@mui/material';
import {Delete, Edit} from '@mui/icons-material';
import React, {useContext, useState} from 'react';
import AddIcon from '@mui/icons-material/Add';
import EventDialog from './EventDialog';
import {UserCellDialogEditContext} from './UserCellDialogEdit';
import Api from '../../../general/classes/Api';
import {ENDPOINTS} from '../../../general/constants/ENDPOINTS';


type EventListEditorProps = {
	events: CalendarEvent[],
	calendarUser: CalendarUser,
	date: Date,
	onUpdate: () => void,
}


export default function EventListEditor(props: EventListEditorProps) {
	const [createEventMode, setCreateEventMode] = useState<boolean>(false);
	const [updateEventMode, setUpdateEventMode] = useState<CalendarEvent | null>(null);
	const [, setStatus] = useContext(UserCellDialogEditContext);
	const api = Api.getInstance();

	const deleteEvent = (event: CalendarEvent) => {
		if (window.confirm('予定を削除します。よろしいですか？')) {
			setStatus({
				loading: true
			})
			api.delete(ENDPOINTS.calendar.event + '/' + event.id).then((resp) => {
				setStatus({
					loading: false
				})
				props.onUpdate();
			})
		}
	}


	return (
		<div className={css.root}>
			{props.events.length > 0 &&
      <table className={css.eventList}>
				{props.events.map((event) => {
					let f = 'H:mm';
					// 日をまたぐ場合は時刻フォーマットを変更
					if (event.startDate.getDate() !== props.date.getDate() || event.endDate.getDate() !== props.date.getDate()) {
						f = 'M月d日 H:mm';
					}

					if (!event.flag) {
						return (
							<tr className={css.eventListItem} key={event.id}>
								<td
									className={css.eventListItem__subject}>{event.subject}
								</td>
								<td className={css.eventListItem__startDate}>{format(event.startDate, f)}</td>
								<td className={css.eventListItem__dateSep}>〜</td>
								<td className={css.eventListItem__endDate}>{format(event.endDate, f)}</td>
								<td><Tooltip placement="bottom-end" title="変更">
									<IconButton size={'small'} color={'primary'}
															onClick={() => {
																setUpdateEventMode(event)
															}}
															className={css.eventListItem__edit}><Edit/></IconButton></Tooltip></td>
								<td><Tooltip placement="bottom-end" title="削除">
									<IconButton size={'small'} color={'primary'}
															onClick={() => {
																deleteEvent(event)
															}}
															className={css.eventListItem__delete}><Delete/></IconButton></Tooltip></td>
							</tr>

						)
					} else {
						return null;
					}
				})}
      </table>
			}
			<Button color={'primary'} variant={'contained'} startIcon={<AddIcon/>}
							onClick={() => setCreateEventMode(true)}>予定を追加</Button>
			{createEventMode &&
      <EventDialog
          defaultOrganizer={props.calendarUser}
          defaultDate={props.date}
          onClose={() => {
						setCreateEventMode(false)
						props.onUpdate();
					}}/>}
			{updateEventMode &&
      <EventDialog
          defaultOrganizer={props.calendarUser}
          defaultDate={props.date}
          event={updateEventMode}
          onClose={() => {
						setUpdateEventMode(null)
						props.onUpdate();
					}}/>}
		</div>


	)
}
