import React, {useContext, useRef} from 'react';
import {format} from 'date-fns';
import {CalendarDataContext} from '../../contexts/CalendarDataContext';
import {EventSearchContext} from '../../contexts/EventSearchContext';
import {EventSearchResult} from '../../hooks/UseEventList';
import ChangerComponent, {ChangerComponentProp} from '../Calendar/ChangerComponent';
import SubActions from './SubActions';

export default function HeaderComponent() {

	const [calendarData] = useContext(CalendarDataContext);
	const result: EventSearchResult = useContext(EventSearchContext);

	const prevMonth = new Date(result.start);
	prevMonth.setMonth(prevMonth.getMonth() - 1);
	const nextMonth = new Date(result.start);
	nextMonth.setMonth(nextMonth.getMonth() + 1);

	const prevMonthUrl = '/calendar/' + calendarData.id + '/personal/monthly/' + prevMonth.getFullYear() + '/' + (prevMonth.getMonth() + 1);
	const nextMonthUrl = '/calendar/' + calendarData.id + '/personal/monthly/' + nextMonth.getFullYear() + '/' + (nextMonth.getMonth() + 1);
	const todayUrl = '/calendar/' + calendarData.id + '/personal/monthly';
	const dateString = format(result.start, 'yyyy年M月');


	const props: ChangerComponentProp = {
		title: dateString,
		centerLinkTitle: '今月',
		centerLinkUrl: todayUrl,
		prevLinkUrl: prevMonthUrl,
		nextLinkUrl: nextMonthUrl,
		subActionNode: <SubActions/>
	}

	return (
		<ChangerComponent {...props} />
	)
}
