import React from 'react';
import css from './ShiftDayGridScale.module.scss';


type GridScaleProps = {
	startHour: number,
	interval: number,
	size: number,
}


const ShiftDayGridScale = (props: GridScaleProps) => {


	const scales: string[] = [];

	for (let i = 0; i < props.size; i++) {
		const num = props.startHour + i * props.interval;
		const hour = (Math.floor(num / 60) < 10) ?  Math.floor(num / 60) : Math.floor(num / 60)
		const min = (num % 60 < 10) ? '0' + (num % 60) : num % 60
		scales.push(hour + ':' + min);
	}

	return (
		<div className={css.scales}>
			{scales.map((text, index) => {
				return (
					<div className={css.scale} key={index}>{text}</div>
				)
			})}
		</div>
	);

};

export default ShiftDayGridScale;
