import React, {useContext} from 'react';
import {useParams} from 'react-router-dom';
import ShiftDay from './ShiftDay';
import css from './Shift.module.scss'
import ShiftDataProvider from '../context/ShiftDataContext';
import ShiftDayDataProvider from '../context/ShiftDayDataContext';
import uuid from 'react-uuid';


export default function Shift() {

	const params = useParams();
	const shiftId = (params.id) ? parseInt(params.id, 10) : 1;

	const today = new Date();
	const date = new Date(
		(params.year) ? parseInt(params.year, 10) : today.getFullYear(),
		(params.month) ? parseInt(params.month, 10) - 1 : (today.getMonth() + 1) - 1,
		(params.day) ? parseInt(params.day, 10) : (today.getDate())
	);


	return (
		<ShiftDataProvider shiftId={shiftId}>
			<ShiftDayDataProvider shiftId={shiftId} date={date}>
				<div className={css.root}>
					<ShiftDay/>
				</div>
			</ShiftDayDataProvider>
		</ShiftDataProvider>
	)
}
