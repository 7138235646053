import React, {ReactNode} from 'react';
import SpinnerContainer from '../../general/components/SpinnerContainer';
import useEventSearch, {EventSearchCriteria, EventSearchResult} from '../hooks/UseEventList';



export const EventSearchContext = React.createContext<EventSearchResult>(null as any)

type EventListProviderProps = {
	criteria: EventSearchCriteria,
	children: ReactNode
}

export default function EventSearchProvider(props: EventListProviderProps) {

	const [result, loading] = useEventSearch(props.criteria);

	return (
		<EventSearchContext.Provider value={result}>
			<SpinnerContainer loading={loading}>
				{result && props.children}
			</SpinnerContainer>
		</EventSearchContext.Provider>
	)
}

