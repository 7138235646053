export const ENDPOINTS = {
    auth: '/auth',
    user: {
        list: '/users'
    },
    impersonate: '/impersonate',
    shift: '/shift',
    calendar: {
        index: '/calendar',
        event: '/calendar/event',
        logs: (calendarId: number) => {
            return '/calendar/' + calendarId.toString() + '/logs'
        }
    }
}
