import css from './ShiftDayUsers.module.scss'
import {ShiftDayData} from '../types/ShiftDayData';
import {ShiftData} from '../types/ShiftData';
import {useContext, useEffect, useState} from 'react';
import {ShiftDayUser} from '../types/ShiftDayUser';
import {ShiftUser} from '../types/ShiftUser';
import {ShiftEditorContext} from '../context/ShiftEditorContext';
import AddIcon from '@mui/icons-material/Add';
import {Box, Button, Card, Divider, Typography} from '@mui/material';
import useEventSearch, {EventSearchCriteria, EventSearchResult} from '../../calendar/hooks/UseEventList';
import {ENDPOINTS} from '../../general/constants/ENDPOINTS';
import Api from '../../general/classes/Api';
import {format} from 'date-fns';

export type ShiftDayUsersProps = {
	shiftData: ShiftData,
	shiftDayData: ShiftDayData,
	editMode: boolean,
	onAddShiftUser: (shiftUser: ShiftUser) => void,
	onRemoveShiftDayUser: (shiftDayUser: ShiftDayUser) => void
}


export interface ShiftDayUsersHandler {
	startEdit: () => void,
	cancelEdit: () => void,
	submitEdit: () => void
}

export default function ShiftDayUsers() {

	const {editor} = useContext(ShiftEditorContext);
	const [eventSearchResult, setEventSearchResult] = useState<EventSearchResult | null>(null);
	const api = Api.getInstance();
	const renderIcon = (member: ShiftDayUser) => {
		return (
			<></>
		)
	}


	useEffect(() => {
		const startDateTime = new Date(editor.tmpShiftDayData.date);
		startDateTime.setHours(0);
		startDateTime.setMinutes(0);
		startDateTime.setSeconds(0);
		const endDateTime = new Date(editor.tmpShiftDayData.date);
		endDateTime.setHours(23);
		endDateTime.setMinutes(59);
		endDateTime.setSeconds(59);
		const criteria: EventSearchCriteria = {
			calendarId: 1,
			start: startDateTime,
			end: endDateTime
		}

		api.get<EventSearchResult>(ENDPOINTS.calendar.event, criteria).then((data) => {
			data.events.forEach((event) => {
				event.startDate = new Date(event.startDate);
				event.endDate = new Date(event.endDate);
			})
			console.log(data.events);
			setEventSearchResult(data);
		}).finally(() => {
		})

	}, [editor.tmpShiftDayData.shiftDayUsers])

	return (
		<div className={css.root}>
			<Typography variant={'h6'}>当日担当者</Typography>
			<Divider sx={{mt: 0.5, mb: 1}}/>
			{editor.tmpShiftDayData.shiftDayUsers.length === 0 && (
				<div className={css.memberNotFound}>当日担当者はまだ設定されていません。</div>
			)}
			{editor.tmpShiftDayData.shiftDayUsers.length > 0 && (
				<div className={css.members}>
					{editor.tmpShiftDayData.shiftDayUsers.map((member) => {
						return (
							<Card variant={'outlined'} key={member.user.id} sx={{mb: 0.5, p: 0}}>
								<Box sx={{p: .5, pl: 1}}>
									<Typography component={'div'} fontWeight={'bold'} variant={'body1'}
															gutterBottom>{member.user.displayName}{renderIcon(member)}</Typography>

									<div className={css.member__schedule}>
										{eventSearchResult && eventSearchResult.events.filter((event) => {
											let result = false;
											event.eventUsers.forEach((eventUser) => {
												if (eventUser.userId === member.user.id) {
													result = true;
												}
											})
											return result;
										}).map((event) => {
											let range = '';
											if (!event.flag) {
												range = format(event.startDate, 'HH:mm') + '-' + format(event.endDate, 'HH:mm');
											}
											return (
												<Typography component={'div'}
																		variant={'caption'}>{event.subject} {range}</Typography>
											)
										})}
									</div>
								</Box>

								{/**/}
								{/*	<div>会議 15:00〜16:00</div>*/}
								{/*</div>*/}
							</Card>
						)
					})}
				</div>
			)}
			{editor.isEditMode &&
      <Button
          variant={'contained'}
          startIcon={<AddIcon/>}
          onClick={() => {
						editor.setShowShiftUserSelectionModal(true)
					}}>担当者変更</Button>
			}
		</div>
	)
}

