import {
	Drawer,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	ListSubheader
} from '@mui/material';
import {format} from 'date-fns';
import ja from 'date-fns/locale/ja';
import React, {useContext, useState} from 'react';
import {ShiftDayDataContext} from '../context/ShiftDayDataContext';

import {ShiftDayRevision} from '../types/ShiftDayRevision';
import ShiftDayRevisionDetail from './ShiftDayRevisionDetail';

type ShiftDayRevisionsProps = {
	open: boolean,
	onClose: () => void
}

export default function ShiftDayRevisions(props: ShiftDayRevisionsProps) {
	const {shiftDayResponse} = useContext(ShiftDayDataContext);

	const [revision, setRevision] = useState<ShiftDayRevision | null>(null)
	return (
		<>
			<Drawer
				anchor={'right'}
				open={props.open}
				onClose={props.onClose}
			>
				<List subheader={<ListSubheader>更新履歴({shiftDayResponse.revisions.length})</ListSubheader>}>
					{shiftDayResponse.revisions.map((revision) => {
						return (
							<ListItem sx={{pb: 0, pt: 0}}>
								<ListItemButton dense={true} onClick={() => {
									setRevision(revision)
								}}>
									<ListItemText>
										<span>{format(revision.createdAt, 'yyyy/MM/dd(E) HH:mm', {locale: ja})} {revision.user.displayName}</span>
									</ListItemText>
								</ListItemButton>
							</ListItem>
						)
					})}
				</List>
			</Drawer>
			{revision && <ShiftDayRevisionDetail revision={revision} onClose={() => {
				setRevision(null)
			}}/>}
		</>

	)
}
