import css from './UserMenu.module.scss';
import {Button, ListItemIcon, ListItemText, Menu, MenuItem} from '@mui/material';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import {useCallback, useContext} from 'react';
import {AuthContext} from '../../auth/contexts/AuthContext';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import TabletMacIcon from '@mui/icons-material/TabletMac';
import {Link} from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import ListAltIcon from '@mui/icons-material/ListAlt';
import usePermission from "../../auth/hooks/usePermission";
import {PERMISSION} from "../../auth/types/PERMISSION";
import LoginIcon from '@mui/icons-material/Login';
import useImpersonate from "../../auth/hooks/useImpersonate";
import Api from "../../general/classes/Api";
import {ENDPOINTS} from "../../general/constants/ENDPOINTS";
import {UserTypes} from "../../auth/types/UserTypes";
import {useIdleTimer} from 'react-idle-timer';
import useFlash from "../../general/hooks/UseFlash";

export default function UserMenu() {

    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const [loginInfo] = useContext(AuthContext);
    const {hasPermission} = usePermission();
    const {showImpersonateDialog} = useImpersonate();
    const {flash} = useFlash();

    useIdleTimer({
        onIdle: (e, timer) => {
            if (loginInfo.impersonator) {
                logout();
            }
        },
        timeout: 60_000,
        throttle: 1000
    })


    const handleOpenUserMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    }, []);

    const handleCloseUserMenu = useCallback(() => {
        setAnchorElUser(null);
    }, []);

    const onClickLogout = useCallback(() => {

        if (hasPermission(PERMISSION.UserImpersonateExec) && !window.confirm('ゲストユーザーをログアウトします。本当によろしいですか?')) {
            return;
        }
        logout();

    }, [hasPermission]);

    const logout = () => {
        const api = Api.getInstance();
        api.delete(ENDPOINTS.auth).then((response) => {
            // localStorage.removeItem(CONFIG.localStorageKeys.bearerToken);
            if (loginInfo.impersonator) {
                flash.info('一定時間操作がなかったため、簡易ログインを終了しました。', 10000, true);
            } else {
                flash.success('ログアウトしました', 6000, true);
            }
            window.location.reload();
        }).catch(() => {

        }).finally(() => {

        })
    }

    const renderAccountIcon = useCallback(() => {
        if (loginInfo.user.type !== UserTypes.Human) {
            return <TabletMacIcon fontSize={'large'}/>;
        } else if (loginInfo.user.type === UserTypes.Human) {
            return <AccountCircleIcon fontSize={'large'}/>;
        } else {
            return <></>
        }
    }, [loginInfo])

    const onClickImpersonate = useCallback(() => {
        showImpersonateDialog();
    }, [showImpersonateDialog]);


    return (
        <>
            <Button variant={'text'} onClick={handleOpenUserMenu} sx={{color: 'white'}}>
                {renderAccountIcon()}
                <div>
                    {loginInfo.impersonator &&
                        <Typography
                            sx={{
                                ml: 1,
                                background: '#33691E',
                                paddingLeft: '.8em',
                                paddingRight: '.8em',
                                borderRadius: '.5em .5em'
                            }}
                            fontSize={"smaller"}
                        >簡易ログイン中</Typography>
                    }
                    <Typography sx={{ml: 1}}>{loginInfo.user.displayName}</Typography>
                </div>
            </Button>
            <Menu
                sx={{mt: '45px'}}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                {!hasPermission(PERMISSION.UserImpersonateExec) &&
                    <MenuItem component={Link} to={'/user/profile'}>
                        <ListItemIcon>
                            <ListAltIcon/>
                        </ListItemIcon>
                        <ListItemText>
                            <Typography textAlign="center">プロフィール</Typography>
                        </ListItemText>
                    </MenuItem>
                }
                {hasPermission(PERMISSION.UserImpersonateExec) &&
                    <MenuItem onClick={onClickImpersonate}>
                        <ListItemIcon>
                            <LoginIcon/>
                        </ListItemIcon>
                        <ListItemText>
                            <Typography textAlign="center">簡易ログイン</Typography>
                        </ListItemText>
                    </MenuItem>
                }
                <MenuItem onClick={onClickLogout}>
                    <ListItemIcon>
                        <LogoutIcon/>
                    </ListItemIcon>
                    <ListItemText>
                        <Typography textAlign="center">ログアウト</Typography>
                    </ListItemText>
                </MenuItem>
            </Menu>
        </>
    )
}
