import {AxiosError} from 'axios';
import {ShiftData} from '../types/ShiftData';

import {useContext, useEffect, useState} from 'react';
import Api from '../../general/classes/Api';
import {ENDPOINTS} from '../../general/constants/ENDPOINTS';
import {FlashMessageContext} from "../../general/contexts/FlashMessageContext";
import {useNavigate} from "react-router-dom";

export default function useShiftData(shiftId: number) {
    // [data: ShiftData | null, setShiftData: any, axiosError: AxiosError | null, unknownError: unknown | null] {
    // const converter = (data: unknown) => {
    // 	return data as ShiftData;
    // }
    const [shiftData, setShiftData] = useState(null as any);
    const {flashErrorHandler} = useContext(FlashMessageContext);
    const navigate = useNavigate();

    const api = Api.getInstance();
    const get = () => {
        api.get<ShiftData>(ENDPOINTS.shift + '/' + shiftId).then((data) => {
            setShiftData(data as ShiftData);
        }).catch((e) => {
            flashErrorHandler(e);
            navigate('/');
        }).finally(() => {

        })
    }

    useEffect(() => {
        get()
    }, [shiftId])
    // const [shiftData, setShiftData, axiosError, unknownError] = useApiGet<ShiftData>('/shift/' + shiftId, converter)

    return [shiftData, setShiftData];
}
