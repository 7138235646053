import React, {Dispatch, SetStateAction, useState} from 'react';
import {format} from 'date-fns';
import {CalendarUser} from '../../types/CalendarUser';
import {CalendarEvent} from '../../types/CalendarEvent';
import {
	Card, CardContent, CircularProgress,
	Dialog, DialogContent, DialogTitle, IconButton,
} from '@mui/material';
import {ja} from 'date-fns/locale';
import {Close} from '@mui/icons-material';
import EventListEditor from './EventListEditor';
import EventFlagEditor from './EventFlagEditor';
import {UserTypes} from '../../../auth/types/UserTypes';
import css from './UserCellDialogEdit.module.scss';


type UserCellDialogProps = {
	calendarUser: CalendarUser,
	date: Date,
	events: CalendarEvent[],
	onClose: () => void;
}

type UserCellDialogEditStatus = {
	loading: boolean
}
type UserCellDialogEditContextType = [
	status: UserCellDialogEditStatus,
	setStatus: Dispatch<SetStateAction<UserCellDialogEditStatus>>
]
export const UserCellDialogEditContext = React.createContext<UserCellDialogEditContextType>(null as any)

export default function UserCellDialogEdit(props: UserCellDialogProps) {

	const [status, setStatus] = useState<UserCellDialogEditStatus>({
		loading: false
	})

	return (
		<UserCellDialogEditContext.Provider value={[status, setStatus]}>
			<Dialog
				fullScreen={false}
				open={true}
				fullWidth={true}
				maxWidth={'sm'}
				onClose={props.onClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{format(props.date, 'yyyy年M月d日(E)', {locale: ja})} {props.calendarUser.user.displayName}
					<IconButton
						aria-label="close"
						onClick={() => {
							props.onClose();
						}}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					><Close/></IconButton>
				</DialogTitle>
				<DialogContent>
					{status.loading && <div className={css.loadingOverlay}><CircularProgress/></div>}
					{(props.calendarUser.user.type === UserTypes.Human) &&
          <Card variant={'outlined'} sx={{mb: 1}}>
              <CardContent>
                  <EventFlagEditor
                      events={props.events}
                      calendarUser={props.calendarUser}
                      date={props.date}
                      onUpdate={() => {
												// props.onClose()
											}}
                  />
              </CardContent>
          </Card>
					}
					<Card variant={'outlined'}>
						<CardContent>
							<EventListEditor
								events={props.events}
								calendarUser={props.calendarUser}
								date={props.date}
								onUpdate={() => {
									// props.onClose()
								}}/>
						</CardContent>
					</Card>
				</DialogContent>
			</Dialog>
		</UserCellDialogEditContext.Provider>
	)

}
