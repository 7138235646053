import React, {useContext} from 'react';
import ShiftDayTemplateSelector from '../components/ShiftDayTemplateSelector';
import css from './Shift.module.scss';
import Header from '../components/Header';
import ShiftDayGrid from '../components/ShiftDayGrid';
import {ShiftDayDataContext} from '../context/ShiftDayDataContext';
import ShiftEditorProvider from '../context/ShiftEditorContext';

export default function ShiftDay() {

	const {shiftDayResponse} = useContext(ShiftDayDataContext);

	if (shiftDayResponse.shiftDayData.template.id !== 0) {
		return (
			<>
				<ShiftEditorProvider initialShiftDayData={shiftDayResponse.shiftDayData}>
					<Header/>
					<ShiftDayGrid/>
				</ShiftEditorProvider>
			</>
		)
	} else {
		return (
			<>
				<ShiftEditorProvider initialShiftDayData={shiftDayResponse.shiftDayData}>
					<Header/>
					<ShiftDayTemplateSelector/>
				</ShiftEditorProvider>
			</>
		)
	}
}
