import {Link, Outlet} from 'react-router-dom';
import * as React from 'react';
import {styled, useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Sidebar from './Sidebar';
import {AppBar, Button, useMediaQuery} from '@mui/material';
import UserMenu from './components/UserMenu';
import {useContext, useEffect, useRef} from "react";
import {LayoutContext} from "./contexts/LayoutContext";


export default function Layout() {
    const theme = useTheme();
    const [layoutStatus, setLayoutStatus] = useContext(LayoutContext);
    const [open, setOpen] = React.useState(false);

    const appBarRef = useRef<HTMLDivElement>(null);
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));

    const handleOpen = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    }

    useEffect(() => {
        if (appBarRef.current) {
            const tmp = {...layoutStatus};
            tmp.appBarHeight = appBarRef.current.clientHeight;
            setLayoutStatus(tmp);
        }
    }, [appBarRef, isMobile])


    return (
        <>
            <AppBar position="fixed">
                <Toolbar ref={appBarRef}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleOpen}
                        edge="start"
                        sx={{mr: 2}}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Button component={Link} to={'/'} sx={{color: '#fff'}} size={'large'}>鹿島神宮</Button>
                    <Box sx={{flexGrow: 1}}/>
                    <Box sx={{flexGrow: 0}}>
                        <UserMenu/>
                    </Box>
                </Toolbar>
            </AppBar>

            <Drawer anchor='left' open={open} onClose={handleClose}>
                <div>
                    <Toolbar style={{width: "320px", display: "flex", justifyContent: "flex-end"}}>
                        <IconButton onClick={handleClose}>
                            {theme.direction === 'ltr' ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
                        </IconButton>
                    </Toolbar>
                    <Divider/>
                    <Sidebar onClose={handleClose}/>
                </div>
            </Drawer>
            <div style={{paddingTop: layoutStatus.appBarHeight.toString() + "px"}}>
                <Outlet/>
            </div>
        </>
    );
}
