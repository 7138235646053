import React, {ReactNode} from 'react';
import css from './SpinnerContainer.module.scss';
import {CircularProgress} from '@mui/material';


export interface SpinnerContainerProps {
	loading: boolean,
	inner?: boolean
	children: ReactNode
}

export default function SpinnerContainer(props: SpinnerContainerProps) {

	return (
		<div className={css.root}>
			<div className={`${css.body} ${(props.loading) ? css.bodyLoading : ''}`}>{props.children}</div>
			{(props.loading && props.inner === true) && (
				<div className={css.innerSpinner}><CircularProgress/></div>
			)}
			{(props.loading && props.inner !== true) && (
				<div className={css.globalSpinner}><CircularProgress/></div>
			)}
		</div>
	)
}
