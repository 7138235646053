import css from './DailyCalendarPage.module.scss';
import {useParams} from 'react-router-dom';
import {useContext, useRef, useState} from 'react';
import {CalendarDataContext} from '../contexts/CalendarDataContext';
import {EventSearchCriteria} from '../hooks/UseEventList';
import EventSearchProvider from '../contexts/EventSearchContext';
import HeaderComponent from '../components/DailyCalendar/HeaderComponent';
import ListComponent from '../components/DailyCalendar/ListComponent';



export default function DailyCalendarPage() {

	const params = useParams<any>();
	const [calendarData] = useContext(CalendarDataContext);


	const today = new Date();
	const year = (params.year) ? parseInt(params.year, 10) : today.getFullYear();
	const month = (params.month) ? parseInt(params.month, 10) : (today.getMonth() + 1);
	const day = (params.day) ? parseInt(params.day, 10) : (today.getDate());

	const start = new Date(year, month - 1, day, 0, 0, 0);
	const end = new Date(year, month - 1, day + 1, 0, 0, 0);
	end.setSeconds(-1);

	const criteria: EventSearchCriteria = {
		calendarId: calendarData.id,
		start,
		end
	}

	return (
		<EventSearchProvider criteria={criteria}>
			<HeaderComponent/>
			<ListComponent/>
		</EventSearchProvider>
	)
}
