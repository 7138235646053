import React from 'react';
import css from './EditOverlay.module.scss';
import {Button} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';

type EditOverlayProps = {
	onSubmit: () => void,
	onCancel: () => void,
	show: boolean
}

export default function EditOverlay(props: EditOverlayProps) {
	return (
		<div className={css.root} data-show={(props.show) ? 1 : 0}>
			<div className={css.actions}>
				<label>編集中</label>
				<Button startIcon={<ClearIcon/>} onClick={props.onCancel} disabled={!props.show} variant={'contained'}
								color={'inherit'} sx={{color: '#000'}}>キャンセル</Button>
				<Button startIcon={<CheckIcon/>} onClick={props.onSubmit} disabled={!props.show} variant={'contained'}
								color={'primary'}>確定</Button>
			</div>
			<div className={css.backdrop}/>
		</div>
	)
}

