import React from 'react';
import Echo from 'laravel-echo';

const laravelEcho = new Echo({
	broadcaster: 'socket.io',
	host: process.env.REACT_APP_ECHO_URL,
	client: require('socket.io-client')
})

export const LaravelEchoContext = React.createContext<Echo>(laravelEcho);

function LaravelEchoProvider(props: any) {
	return (
		<LaravelEchoContext.Provider value={laravelEcho} {...props} />
	);
}

export default LaravelEchoProvider;
