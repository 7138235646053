import React, {ReactNode, useContext, useEffect, useState} from 'react';
import LoginPage from '../pages/LoginPage';
import Api from '../../general/classes/Api';
import {ENDPOINTS} from '../../general/constants/ENDPOINTS';
import {CONFIG} from '../../general/constants/CONFIG';
import {FlashMessageContext} from '../../general/contexts/FlashMessageContext';
import {LoginInfo} from "../types/LoginInfo";


type AuthContextType = [
    loginInfo: LoginInfo,
    setLoginInfo: (me: LoginInfo) => void,
]
export const AuthContext = React.createContext<AuthContextType>(null as any)

type AuthProviderProps = {
    children: ReactNode;
}


export default function AuthProvider(props: AuthProviderProps) {

    const [loginInfo, setLoginInfo] = useState<LoginInfo>(null as any)
    const [loading, setLoading] = useState<boolean>(true);
    const {flashErrorHandler} = useContext(FlashMessageContext);
    const api = Api.getInstance();

    const readToken = (): string => {
        let token = localStorage.getItem(CONFIG.localStorageKeys.bearerToken);
        if (!token) {
            return '';
        }
        return token;
    }
    const writeToken = (token: string) => {
        localStorage.setItem(CONFIG.localStorageKeys.bearerToken, token);
    }

    useEffect(() => {
        api.setToken(readToken());
        api.get<LoginInfo>(ENDPOINTS.auth).then((data) => {
            setLoginInfo(data);
        }).catch((e) => {

        }).finally(() => {
            setLoading(false);
        })
    }, [])

    const successLoginHandler = (loginInfo: LoginInfo, token: string) => {
        setLoginInfo(loginInfo);
        writeToken(token);
        api.setToken(token);
        // api.setErrorInterceptor(apiErrorHandler)
    }

    return (
        <AuthContext.Provider value={[loginInfo, setLoginInfo]}>
            {loading && <></>}
            {!loading &&
                <>
                    {loginInfo && props.children}
                    {!loginInfo && <LoginPage successLoginHandler={successLoginHandler}/>}
                </>
            }
        </AuthContext.Provider>
    )
}

