import {ShiftDayRevision} from '../types/ShiftDayRevision';
import {Box, Dialog, DialogContent, DialogTitle, IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {format} from 'date-fns';
import ja from 'date-fns/locale/ja';

type ShiftDayRevisionDetailProps = {
	revision: ShiftDayRevision,
	onClose: () => void
}

export default function ShiftDayRevisionDetail(props: ShiftDayRevisionDetailProps) {

	return (
		<Dialog
			fullScreen={false}
			open={true}
			fullWidth={true}
			maxWidth={'sm'}
			onClose={props.onClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				{format(props.revision.createdAt, 'yyyy/MM/dd(E) HH:mm', {locale: ja})}<br/>編集者：{props.revision.user.displayName}
				<IconButton
					aria-label="close"
					onClick={props.onClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon/>
				</IconButton>
			</DialogTitle>
			<DialogContent>
				履歴閲覧部分は開発中
			</DialogContent>
		</Dialog>
	)
}
