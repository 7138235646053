import React, {useState} from "react";
import ImpersonateDialog from "../components/ImpersonateDialog";

type LayoutContextType = {
    showImpersonateDialog: () => void
}

export const ImpersonateContext = React.createContext<LayoutContextType>(null as any)

export default function ImpersonateProvider(props: any) {

    const [showDialog, setShowDialog] = useState<boolean>(false);
    const showImpersonateDialog = () => {
        setShowDialog(true);
    }

    return (
        <ImpersonateContext.Provider value={{showImpersonateDialog}}>
            {props.children}
            {showDialog && <ImpersonateDialog onClose={() => {
                setShowDialog(false)
            }}/>}
        </ImpersonateContext.Provider>
    )
}

