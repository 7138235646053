import {useContext, useEffect, useRef, useState} from 'react';
import {CalendarEvent} from '../types/CalendarEvent';
import {LaravelEchoContext} from '../../general/contexts/LaravelEchoContext';
import {cloneDeep} from 'lodash';
import Api from '../../general/classes/Api';
import {ENDPOINTS} from '../../general/constants/ENDPOINTS';


export type EventSearchCriteria = {
    calendarId: number,
    start: Date,
    end: Date
}

export type EventSearchResult = {
    start: Date,
    end: Date,
    calendarId: number,
    offset: number,
    limit: number,
    numMatches: number,
    events: CalendarEvent[]
}

export default function useEventSearch(criteria: EventSearchCriteria): [EventSearchResult, boolean] {
    const [eventSearchResult, setEventSearchResult] = useState(null as any);
    // このモジュール用に検索結果を保存するコンテナ
    const eventSearchResultRef = useRef<EventSearchResult>(null as any);
    const [loading, setLoading] = useState(true);
    const laravelEchoContext = useContext(LaravelEchoContext);
    const api = Api.getInstance();

    const convertEvent = (event: any) => {
        event.startDate = new Date(event.startDate);
        event.endDate = new Date(event.endDate);
    }


    const convertPayload = (payload: any) => {
        if (payload.start) {
            payload.start = new Date(payload.start);
        }
        if (payload.end) {
            payload.end = new Date(payload.end);
        }
        payload.events.forEach((event: any) => {
            convertEvent(event)
        })
    }

    const eventSort = (a: CalendarEvent, b: CalendarEvent) => {
        if (a.startDate.getTime() === b.startDate.getTime()) {
            return 0;
        }
        return (a.startDate > b.startDate) ? 1 : -1;
    }

    const get = () => {
        api.get<EventSearchResult>(ENDPOINTS.calendar.event, criteria).then((data) => {
            convertPayload(data);
            data.events.sort(eventSort);
            eventSearchResultRef.current = data;
            setEventSearchResult(data)
        }).finally(() => {
            setLoading(false);
        })
    }

    const handleReceiveEvent = (ev: any) => {
        if (eventSearchResultRef.current) {
            if (ev.type === 'create') {
                convertEvent(ev.payload);
                if (eventSearchResultRef.current.start <= ev.payload.startDate && eventSearchResultRef.current.end >= ev.payload.endDate) {
                    eventSearchResultRef.current.events.push(ev.payload);
                    eventSearchResultRef.current.events.sort(eventSort);
                    setEventSearchResult(cloneDeep(eventSearchResultRef.current));
                }
            } else if (ev.type === 'delete') {
                eventSearchResultRef.current.events = eventSearchResultRef.current.events.filter((event) => {
                    return (event.id !== ev.objectId);
                })
                setEventSearchResult(cloneDeep(eventSearchResultRef.current));
            } else if (ev.type === 'update') {
                const index = eventSearchResultRef.current.events.findIndex((event) => {
                    return (event.id === ev.objectId);
                })
                if (index !== -1) {
                    convertEvent(ev.payload);
                    eventSearchResultRef.current.events.splice(index, 1, ev.payload);
                    eventSearchResultRef.current.events.sort(eventSort);
                    setEventSearchResult(cloneDeep(eventSearchResultRef.current));
                }
            } else {
                get();
            }
        } else {
            get();
        }
    }


    //
    useEffect(() => {
        setLoading(true);
        laravelEchoContext.channel("mychannel").listen(".calendar.updated", handleReceiveEvent);
        get()

        return () => {
            // console.log('calendar.updated stop');
            laravelEchoContext.channel("mychannel").stopListening(".calendar.updated");
        }
        // eslint-disable-next-line
    }, [criteria])

    return [eventSearchResult, loading];
}
