import css from './HeaderComponent.module.scss'
import React, {useContext, useRef, useState} from 'react';
import {format} from 'date-fns';
import {Link, useNavigate} from 'react-router-dom';
import {CalendarDataContext} from '../../contexts/CalendarDataContext';
import {EventSearchContext} from '../../contexts/EventSearchContext';
import {EventSearchResult} from '../../hooks/UseEventList';
import {getRokuyo} from '../../../general/utils/Rokuyo';
import {ja} from 'date-fns/locale';
import {ChangerComponentProp} from '../Calendar/ChangerComponent';
import {IconButton} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PopDatePicker from '../../../general/components/PopDatePicker';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export default function HeaderComponent() {

	const [calendarData] = useContext(CalendarDataContext);
	const result: EventSearchResult = useContext(EventSearchContext);
	const navigate = useNavigate();

	const anchorRef = useRef<HTMLDivElement>(null);
	const [showDatePicker, setShowDatePicker] = useState<boolean>(false);

	const prevDay = new Date(result.start);
	prevDay.setDate(prevDay.getDate() - 1);
	const nextDay = new Date(result.start);
	nextDay.setDate(nextDay.getDate() + 1);
	const prevUrl = '/calendar/' + calendarData.id + '/personal/daily/' + prevDay.getFullYear() + '/' + (prevDay.getMonth() + 1) + '/' + (prevDay.getDate());
	const nextUrl = '/calendar/' + calendarData.id + '/personal/daily/' + nextDay.getFullYear() + '/' + (nextDay.getMonth() + 1) + '/' + (nextDay.getDate());
	const todayUrl = '/calendar/' + calendarData.id + '/personal/daily';
	const rokuyo = getRokuyo(result.start);
	const dateString = format(result.start, 'yyyy年M月d日(E)', {locale: ja}) + ' ' + rokuyo;
	console.log(nextUrl);

	const props: ChangerComponentProp = {
		title: dateString,
		centerLinkTitle: '今日',
		centerLinkUrl: todayUrl,
		prevLinkUrl: prevUrl,
		nextLinkUrl: nextUrl
	}

	const handleShowDatePicker = () => {
		setShowDatePicker(true);
	}

	const handleOnCloseDatePicker = () => {
		setShowDatePicker(false);
	}
	const handleOnChangeDate = (date: Date | null) => {
		if (date) {
			const url = '/calendar/' + calendarData.id + '/personal/daily/' + date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + (date.getDate());
			navigate(url);
		}
	}

	return (
		<header className={css.header}>
			<div className={css.content}>
				{/*<div className={css.name}></div>*/}
				<div className={css.date}  ref={anchorRef} onClick={handleShowDatePicker}>{props.title}<ArrowDropDownIcon fontSize={'large'}/></div>
				<div className={css.navigation}>
					<div className={css.yesterday}><IconButton color={'primary'} component={Link}
																										 to={props.prevLinkUrl}><ArrowBackIosNewIcon/></IconButton></div>
					<div className={css.today}><IconButton color={'primary'} component={Link}
																								 to={props.centerLinkUrl}>{props.centerLinkTitle}</IconButton></div>
					<div className={css.tomorrow}><IconButton color={'primary'} component={Link}
																										to={props.nextLinkUrl}><ArrowForwardIosIcon/></IconButton></div>
				</div>
				<div className={css.dummy}/>
				<div className={css.subActions}>
					{props.subActionNode}
				</div>
			</div>
			{showDatePicker &&
      <PopDatePicker
          show={showDatePicker}
          anchorEl={anchorRef.current}
          onClose={handleOnCloseDatePicker}
          onChangeDate={handleOnChangeDate}
      />
			}
		</header>
	)
}
