import React from 'react';
import './App.scss';
import AuthProvider from '../auth/contexts/AuthContext';
import RouterConfig from './RouterConfig';
import LaravelEchoProvider from '../general/contexts/LaravelEchoContext';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {ja} from 'date-fns/locale';
import FlashMessageProvider from '../general/contexts/FlashMessageContext';
import AxiosErrorHandler from '../general/components/AxiosErrorHandler';
import LayoutProvider from "./contexts/LayoutContext";
import ImpersonateProvider from "../auth/contexts/ImpersonateContext";


function App() {

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
            <LaravelEchoProvider>
                <FlashMessageProvider>
                    <AxiosErrorHandler>
                        <AuthProvider>
                            <ImpersonateProvider>
                                <LayoutProvider>
                                    <RouterConfig/>
                                </LayoutProvider>
                            </ImpersonateProvider>
                        </AuthProvider>
                    </AxiosErrorHandler>
                </FlashMessageProvider>
            </LaravelEchoProvider>
        </LocalizationProvider>
    );
}

export default App;
