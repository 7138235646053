import {format} from 'date-fns';

type DateTimeRangeProps = {
	startDate: Date;
	endDate: Date;
	baseDate: Date;
}

/**
 * baseDateを基準に見て当日中の事柄なら start〜endの時間だけ表示。当日をまたぐ事柄の場合は日付も表示
 * @constructor
 */
export default function DateTimeRange(props: DateTimeRangeProps) {
	let f = 'H:mm';
	// 日をまたぐ場合は時刻フォーマットを変更
	if (props.startDate.getDate() !== props.baseDate.getDate() || props.endDate.getDate() !== props.baseDate.getDate()) {
		f = 'M/d H:mm';
	}
	const range = format(props.startDate, f) + ' - ' + format(props.endDate, f);

	return <>{range}</>
}
