import Api from '../../general/classes/Api';
import {ENDPOINTS} from '../../general/constants/ENDPOINTS';
import {useEffect, useState} from 'react';
import {CalendarEventLogResponse} from '../types/CalendarEventLogResponse';
import {CalendarEvent} from '../types/CalendarEvent';
import parseEventData from '../functions/parseEventData';

export default function useCalendarLog(): [CalendarEventLogResponse | null, any, boolean] {
	const [loading, setLoading] = useState(false);
	const [calendarEventLogs, setCalendarEventLogs] = useState<CalendarEventLogResponse | null>(null);
	const api = Api.getInstance();

	const get = (calendarId: number, start: Date, end: Date, offset?: number, limit?: number) => {
		if (offset === undefined) {
			offset = 0;
		}
		if (limit === undefined) {
			limit = 0;
		}
		setLoading(true);
		api.get<CalendarEventLogResponse>(ENDPOINTS.calendar.logs(calendarId), {
			start,
			end,
			offset,
			limit
		}).then((data) => {
			data.eventLogs.forEach((eventLog) => {
				eventLog.createdAt = new Date(eventLog.createdAt);
				if (eventLog.beforeData) {
					eventLog.beforeData = parseEventData(eventLog.beforeData);
				} else {
					eventLog.beforeData = null;
				}
				if (eventLog.afterData) {
					eventLog.afterData = parseEventData(eventLog.afterData);
				} else {
					eventLog.afterData = null;
				}
			})
			setCalendarEventLogs(data);
		}).finally(() => {
			setLoading(false);
		})
	}
	//
	// useEffect(() => {
	// 	get()
	// }, [start, end])

	return [calendarEventLogs, get, loading];
}
