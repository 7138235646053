import {StaticDatePicker} from '@mui/x-date-pickers-pro';
import {Popover} from '@mui/material';

type PopDatePickerProps = {
	show: boolean
	anchorEl: HTMLDivElement | null
	onClose: () => void
	onChangeDate: (date: Date | null) => void
}

export default function PopDatePicker(props: PopDatePickerProps) {


	const handleOnChangeDate = (date: Date | null) => {
		props.onChangeDate(date);
		props.onClose();
	}

	return (
		<>
			{props.show &&
      <Popover
          open={props.show}
          anchorEl={props.anchorEl}
          onClose={props.onClose}
          anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
          transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
      >
          <StaticDatePicker
              value={null}
              onChange={handleOnChangeDate}
              slots={{
								toolbar: () => <></>,
								switchViewButton: () => <></>,
								actionBar: () => <></>
							}}
						// format='yyyy年MM月dd日'
						// mask='____年__月__日'
						// renderInput={(params:any) => <TextField {...params} />}
          />
      </Popover>
			}
		</>
	)
}
