import React from 'react';

import {Layout} from 'react-grid-layout';
import {ShiftDayUser} from '../../types/ShiftDayUser';
import {User} from '../../../auth/types/User';
import {Box, Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography} from '@mui/material';
import {format} from 'date-fns';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';

type ShiftCreateModalProps = {
	onClose: () => void;
	srcLayout: Layout | null;
	onModalCreate: (user: User, srcLayout: Layout | null) => void;
	users: ShiftDayUser[]
}

export default function CreateShiftAllocationModal(props: ShiftCreateModalProps) {


	return (
		<>
			<Dialog
				fullScreen={false}
				open={true}
				fullWidth={true}
				maxWidth={'sm'}
				onClose={props.onClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					シフト担当者を追加
					<IconButton
						aria-label="close"
						onClick={props.onClose}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon/>
					</IconButton>
				</DialogTitle>
				<DialogContent>
					<Box pt={2} pb={2}>
						{props.users.length !== 0 &&
						props.users.map((shiftDayUser) => {
							return (
								<Button
									key={shiftDayUser.user.id}
									variant={'contained'}
									sx={{
										m: 0.5
									}}
									onClick={() => {
										props.onModalCreate(shiftDayUser.user, props.srcLayout)
									}}
								>{shiftDayUser.user.displayName}</Button>
							)
						})
						}
						{props.users.length === 0 &&
            <Grid container alignItems={'center'} flexDirection={'column'} justifyContent={'center'} spacing={2}>
                <Grid item>
                    <WarningIcon sx={{fontSize: '3em'}} color={'error'}/>
                </Grid>
                <Grid item>
                    <Typography lineHeight={1.5} variant={'body1'} align={'center'} sx={{opacity: '0.7'}}>当日担当者が設定されていません。<br/>先に担当者を追加ください。</Typography>
                </Grid>
            </Grid>
						}
					</Box>
				</DialogContent>
			</Dialog>

		</>

	)

}
