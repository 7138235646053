import React, {ReactNode, useEffect, useState} from 'react';
import {Alert, Snackbar, SnackbarOrigin} from '@mui/material';
import {ApiError} from "../classes/Api";
import useLocalStorage from "../hooks/UseLocalStorage";
import {AxiosError} from "axios";

type FlashMessageContextType = {
    flashMessage: (message: FlashMessage) => void,
    flashErrorHandler: (e: Error | ApiError) => void,
    saveMessage: (message: FlashMessage) => void,
}

export const FlashMessageContext = React.createContext<FlashMessageContextType>(null as any);

type FlashMessageProviderProps = {
    children: ReactNode
}

export type FlashMessage = {
    message: string,
    duration?: number,
    type?: 'success' | 'info' | 'warning' | 'error',
    origin?: SnackbarOrigin
}

export default function FlashMessageProvider(props: FlashMessageProviderProps) {

    const [message, setMessage] = useState<FlashMessage | null>(null);
    const {setLocalStorageValue, getLocalStorageValue, removeLocalStorageValue} = useLocalStorage();
    const flashMessage = (message: FlashMessage) => {
        if (!message.duration) {
            message.duration = 6000;
        }
        if (!message.type) {
            message.type = 'info';
        }
        if (!message.origin) {
            message.origin = {
                vertical: 'top',
                horizontal: 'center'
            }
        }
        setMessage(message);
    }
    const flashErrorHandler = (e: Error | ApiError | AxiosError) => {

        console.log(e);
        if (e instanceof ApiError) {
            const flashMessage: FlashMessage = {
                duration: 6000,
                message: e.message,
                type: 'error',
                origin: {
                    vertical: 'top',
                    horizontal: 'center'
                }
            };
            setMessage(flashMessage);

        } else if (e instanceof AxiosError) {
            const flashMessage: FlashMessage = {
                duration: 6000,
                message: e.message,
                type: 'error',
                origin: {
                    vertical: 'top',
                    horizontal: 'center'
                }
            };
            setMessage(flashMessage);
        } else {
            const flashMessage: FlashMessage = {
                duration: 6000,
                message: e.message,
                type: 'error',
                origin: {
                    vertical: 'top',
                    horizontal: 'center'
                }
            };
            setMessage(flashMessage);
        }
    }

    const handleClose = () => {
        setMessage(null);
    }

    const saveMessage = (message: FlashMessage) => {
        setLocalStorageValue('react_flash_message', JSON.stringify(message));
    }

    useEffect(() => {
        const messageJson = getLocalStorageValue('react_flash_message');
        if (messageJson) {
            try {
                const message: FlashMessage = JSON.parse(messageJson);
                flashMessage(message);
            } catch (e) {
                console.error(e);
            }
            removeLocalStorageValue('react_flash_message');
        }
    }, [])

    return (
        <FlashMessageContext.Provider value={{flashMessage, flashErrorHandler, saveMessage}}>
            {props.children}
            <div>
                <Snackbar
                    anchorOrigin={message?.origin}
                    open={!!(message)}
                    autoHideDuration={message?.duration}
                    onClose={handleClose}
                    // action={action}
                >
                    <Alert onClose={handleClose} severity={message?.type} sx={{width: '100%'}}>
                        {message?.message}
                    </Alert>
                </Snackbar>
            </div>
        </FlashMessageContext.Provider>
    );
}

