import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    TextField
} from "@mui/material";
import React, {useContext, useState} from "react";
import {useForm} from "react-hook-form";
import useAllUserList from "../hooks/useUserList";
import {UserTypes} from "../types/UserTypes";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SpinnerContainer from "../../general/components/SpinnerContainer";
import Api, {ApiError} from "../../general/classes/Api";
import {ENDPOINTS} from "../../general/constants/ENDPOINTS";
import useFlash from "../../general/hooks/UseFlash";
import {LoadingButton} from "@mui/lab";
import {AuthContext} from "../contexts/AuthContext";
import {LoginInfo} from "../types/LoginInfo";

type ImpersonateForm = {
    userId: number | null;
    password: string | null;
}

type ImpersonateDialogProps = {
    onClose: () => void;
};

export default function ImpersonateDialog(props: ImpersonateDialogProps) {


    const {
        register,
        watch,
        setValue,
        getValues,
        handleSubmit
    } = useForm<ImpersonateForm>({
        defaultValues: {
            userId: null,
            password: null
        }
    });

    const [loginInfo, setLoginInfo] = useContext(AuthContext);
    const {userList, userListLoading} = useAllUserList();
    const [sending, setSending] = useState<boolean>(false);
    const {flash} = useFlash();


    const renderUserList = () => {
        if (!userList) {
            return null;
        }
        const humans = userList.filter((user) => {
            return (user.canImpersonate === true);
        })
        return (
            <>
                <DialogContent>
                    {/*<DialogContentText gutterBottom={true}>ゲストは閲覧専用になっています。簡易ログインが必要です。</DialogContentText>*/}
                    <DialogContentText gutterBottom={true}>誰で簡易ログインしますか？</DialogContentText>
                    {humans.map((user) => {
                        return <Button key={user.id} onClick={(e) => {
                            e.preventDefault();
                            setValue('userId', user.id)
                        }}>{user.displayName}</Button>;
                    })}
                </DialogContent>

                <DialogActions><Button onClick={() => {
                    props.onClose()
                }} variant={"outlined"} color={"info"}>キャンセル</Button></DialogActions>

            </>
        )
    }

    const renderPassword = () => {

        let targetUser = null;
        const targetUserId = getValues('userId');
        if (userList) {
            targetUser = userList.find((user) => {
                return (targetUserId === user.id);
            })
        }
        return (
            <>
                <DialogContent>
                    <DialogContentText sx={{mb: 3}}>生月日を入力ください。</DialogContentText>
                    <Box sx={{mb: 2}}>
                        <TextField
                            label={'ログインユーザー'}
                            disabled
                            fullWidth
                            variant="outlined"
                            value={targetUser?.displayName}
                        ></TextField>
                    </Box>
                    <Box sx={{mb: 2}}>
                        <TextField
                            label={'生月日'}
                            autoFocus={true}
                            pattern="[0-9]{4}"
                            fullWidth
                            type={'password'}
                            inputProps={{pattern: "[0-9]{4}"}}
                            helperText="(例) 4月1日生まれの場合は 0401"
                            {...register('password')}
                        ></TextField>
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => {
                        setValue('userId', null)
                    }}>キャンセル</Button>
                    <LoadingButton loading={sending} type={'submit'}
                                   color={'primary'}
                                   variant={'contained'}>送信</LoadingButton>
                </DialogActions>

            </>
        );
    }
    const onSubmit = (data: ImpersonateForm) => {
        const api = Api.getInstance();
        api.post<LoginInfo>(ENDPOINTS.impersonate, data).then((response) => {
            setLoginInfo(response);
            flash.success('簡易ログインしました。', 6000, true);
            props.onClose();
            window.location.reload();
        }).catch((e: any) => {
            if (e instanceof ApiError) {
                flash.error(e.reason);
            }
        }).finally(() => {
            setSending(false);
        })
        setSending(true);

    }

    return (
        <Dialog
            open={true}
            onClose={props.onClose}
            sx={{
                "& .MuiDialog-container": {
                    alignItems: "flex-start",
                },
            }}
        >
            <DialogTitle>簡易ログイン <IconButton
                aria-label="close"
                onClick={props.onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon/>
            </IconButton></DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl variant="standard">
                    <SpinnerContainer loading={userListLoading} inner={true}>
                        <div style={{minWidth: '320px', minHeight: '150px'}}>
                            {userList && watch().userId === null && renderUserList()}
                            {userList && watch().userId !== null && renderPassword()}
                        </div>
                    </SpinnerContainer>
                </FormControl>
            </form>
        </Dialog>
    );
}
