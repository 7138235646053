import css from './ShiftDayTemplateSelector.module.scss'
import {ShiftDayData} from '../types/ShiftDayData';
import {Template} from '../types/Template';
import {format} from 'date-fns';
import {useContext, useRef, useState} from 'react';
import {ShiftDataContext} from '../context/ShiftDataContext';
import {ShiftDayDataContext} from '../context/ShiftDayDataContext';
import {Box, Button, Card, CardContent, Grid, Typography} from '@mui/material';
import Api from '../../general/classes/Api';


export default function ShiftDayTemplateSelector() {

	const {shiftData} = useContext(ShiftDataContext);
	const {shiftDayResponse} = useContext(ShiftDayDataContext);
	const shiftDayData: ShiftDayData = shiftDayResponse.shiftDayData;
	const api = Api.getInstance();

	const [uploading, setUploading] = useState<boolean>(false);
	const onClickTemplate = (template: Template) => {
		setUploading(true);
		api.patch('/shift/' + shiftData.id + '/day/' + format(shiftDayData.date, 'yyyyMMdd') + '/template', {templateId: template.id}).then((result: any) => {
			// setUploading(false);
		}).catch((reject: any) => {
		})
	}

	return (
		<Card sx={{maxWidth: 480, margin: 'auto', padding: '1em'}}>
			<CardContent>
				<Typography variant={'h6'} m={2}> 雛形が選択されていません。<br/>以下より選択ください。</Typography>
				<Grid container spacing={2} sx={{mb: 3}} justifyContent={'center'}>
					{shiftData.templates.map((template) => {
						return (
							<Grid item>
								<Button
									disabled={uploading}
									variant={'contained'}
									onClick={() => {
										onClickTemplate(template)
									}}>
									{uploading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>}
									{uploading && <span> 更新中</span>}
									{!uploading && <span>{template.name}</span>}
								</Button>
							</Grid>
						)
					})}
				</Grid>
				<Typography align={'center'}>※選択後も変更可能</Typography>

			</CardContent>
		</Card>
	)
}
