import {Card, CardContent, Chip, Grid, IconButton, Popover, Stack} from '@mui/material';
import {format} from 'date-fns';
import {ja} from 'date-fns/locale';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import css from './CellPopover.module.scss';
import UserCellDialogEdit from './UserCellDialogEdit';
import React, {useContext, useState} from 'react';
import {CalendarUser} from '../../types/CalendarUser';
import {CalendarEvent} from '../../types/CalendarEvent';
import {CalendarDataContext} from '../../contexts/CalendarDataContext';
import DateTimeRange from './DateTimeRange';
import usePermission from "../../../auth/hooks/usePermission";
import {PERMISSION} from "../../../auth/types/PERMISSION";
import {FlashMessageContext} from "../../../general/contexts/FlashMessageContext";
import useImpersonate from "../../../auth/hooks/useImpersonate";
import useFlash from "../../../general/hooks/UseFlash";


type CellPopoverProps = {
    show: boolean
    anchorEl: HTMLDivElement | null
    onClose: () => void
    date: Date,
    calendarUser: CalendarUser,
    events: CalendarEvent[]
}
export default function CellPopover(props: CellPopoverProps) {

    const [editMode, setEditMode] = useState<boolean>(false);
    const [calendarData] = useContext(CalendarDataContext);
    const {hasPermission} = usePermission();
    const {flash} = useFlash();
    const {showImpersonateDialog} = useImpersonate();


    const findCalendarUser = (userId: number): CalendarUser | null => {
        const results = calendarData.calendarUsers.filter((calendarUser) => {
            return (calendarUser.user.id === userId);
        })
        if (results.length > 0) {
            return results[0];
        }
        return null;
    }

    const flagEvents = props.events.filter((event) => {
        return !!(event.flag);
    })
    const noFlagEvents = props.events.filter((event) => {
        return !(event.flag);
    })

    const onClickSetEditMode = () => {
        if (hasPermission(PERMISSION.CalendarAllWrite)) {
            setEditMode(true);
        } else if (hasPermission(PERMISSION.UserImpersonateExec)) {
            showImpersonateDialog();
        } else {
            flash.error('権限がありません');
        }
    }

    return (
        <Popover
            open={props.show}
            anchorEl={props.anchorEl}
            onClose={props.onClose}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
            }}
        >
            <Card>
                <Grid container spacing={1} alignItems={'center'} sx={{px: 2, py: 1}}>
                    <Grid item>
                        {format(props.date, 'yyyy年M月d日(E)', {locale: ja})} {props.calendarUser.user.displayName}
                    </Grid>
                    <Grid item>
                        <IconButton
                            color={'primary'}
                            onClick={() => {
                                onClickSetEditMode()
                            }}
                        ><ModeEditIcon/></IconButton>
                    </Grid>
                </Grid>
                <CardContent sx={{px: 2, py: 0}}>
                    {(props.events.length === 0) && <div className={css.noEvents}>予定なし</div>}
                    {flagEvents.length > 0 &&
                        <Stack direction="row" spacing={0.5} sx={{mb: 2}}>
                            {flagEvents.map((event) => {
                                return <Chip label={event.flag?.name} color="primary" key={event.id}/>
                            })}
                        </Stack>
                    }
                    <div className={css.events}>
                        {noFlagEvents.map((event) => {
                            return (
                                <div key={event.id} className={css.event}>
                                    <div className={css.eventTop}>
                                        <div className={css.eventbody}>
                                            <span className={css.eventSubject}>{event.subject}</span>
                                            {!event.isAllDay &&
                                                <span className={css.eventSubjectDateRange}><DateTimeRange
                                                    startDate={event.startDate} endDate={event.endDate}
                                                    baseDate={props.date}/></span>
                                            }
                                        </div>
                                    </div>
                                    {(event.eventUsers.length > 1) &&
                                        <div className={css.eventBottom}>
                                            <div className={css.eventUsers}>
                                                {event.eventUsers.map((eventUser) => {
                                                    const calendarUser = findCalendarUser(eventUser.userId);
                                                    return (
                                                        <div
                                                            key={eventUser.userId.toString() + ',' + eventUser.eventId.toString()}
                                                            className={css.eventUser}>{calendarUser?.user.displayName}</div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    }
                                </div>
                            )
                        })}
                    </div>
                </CardContent>
            </Card>
            {editMode &&
                <UserCellDialogEdit calendarUser={props.calendarUser} date={props.date} events={props.events}
                                    onClose={() => {
                                        setEditMode(false)
                                    }}/>}
        </Popover>
    )
}
