import {useContext} from 'react';
import {AuthContext} from '../contexts/AuthContext';
import UnderConstruction from '../../general/components/UnderConstruction';

export default function ProfilePage() {

	const [user] = useContext(AuthContext);

	return (
		<>
			<UnderConstruction>プロフィールページ</UnderConstruction>
		</>
	)
}
