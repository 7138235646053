import {useEffect} from 'react';
import axios from 'axios';

export default function AxiosErrorHandler(props: any) {

	// useEffect(() => {
	// 	const interceptor = axios.interceptors.response.use(function (response) {
	// 		return response;
	// 	}, function (error) {
	// 		console.log(error);
	// 		// return Promise.reject(error);
	// 	});
	//
	// 	return () => {
	// 		axios.interceptors.response.eject(interceptor);
	// 	}
	// })

	return (
		<>{props.children}</>
	)
}
