import React, {useContext, useRef, useState} from 'react';
import css from './Header.module.scss';
import {ShiftData} from '../types/ShiftData';
import {format} from 'date-fns';
import {ja} from 'date-fns/locale';
import {Link, useNavigate} from 'react-router-dom';

import {getRokuyo} from '../../general/utils/Rokuyo';
import {ShiftDataContext} from '../context/ShiftDataContext';
import {ShiftDayDataContext} from '../context/ShiftDayDataContext';
import {ShiftEditorContext} from '../context/ShiftEditorContext';
import {ShiftDayData} from '../types/ShiftDayData';
import ChangerComponent from './ChangerComponent';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {IconButton} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PopDatePicker from '../../general/components/PopDatePicker';
import {ChangerComponentProp} from '../../calendar/components/Calendar/ChangerComponent';

export default function Header() {

	const {shiftData} = useContext(ShiftDataContext);
	const {shiftDayResponse} = useContext(ShiftDayDataContext);
	const {editor} = useContext(ShiftEditorContext);
	const shiftDayData: ShiftDayData = shiftDayResponse.shiftDayData;

	const navigate = useNavigate();

	const anchorRef = useRef<HTMLDivElement>(null);
	const [showDatePicker, setShowDatePicker] = useState<boolean>(false);

	const yesterdayDate = new Date(shiftDayData.date);
	const tomorrowDate = new Date(shiftDayData.date);
	tomorrowDate.setDate(shiftDayData.date.getDate() + 1);
	yesterdayDate.setDate(shiftDayData.date.getDate() - 1);

	const yesterdayUrl = '/shift/' + shiftData.id + '/' + yesterdayDate.getFullYear() + '/' + (yesterdayDate.getMonth() + 1) + '/' + yesterdayDate.getDate();
	const tomorrowUrl = '/shift/' + shiftData.id + '/' + tomorrowDate.getFullYear() + '/' + (tomorrowDate.getMonth() + 1) + '/' + tomorrowDate.getDate();
	const todayUrl = '/shift/' + shiftData.id;
	const rokuyo = getRokuyo(shiftDayData.date);
	const dateString = format(shiftDayData.date, 'yyyy年M月d日(E) ' + rokuyo, {locale: ja});

	const props: ChangerComponentProp = {
		title: dateString,
		centerLinkTitle: '今日',
		centerLinkUrl: todayUrl,
		prevLinkUrl: yesterdayUrl,
		nextLinkUrl: tomorrowUrl
	}


	const handleShowDatePicker = () => {
		if (!editor.isEditMode) {
			setShowDatePicker(true);
		}
	}

	const handleOnCloseDatePicker = () => {
		setShowDatePicker(false);
	}
	const handleOnChangeDate = (date: Date | null) => {
		if (date) {
			const url = '/shift/' + shiftData.id + '/' + date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate();
			navigate(url);
		}
	}

	return (
		<header className={css.header}>
			<div className={css.content}>
				{/*<div className={css.name}></div>*/}
				<div className={css.date} ref={anchorRef} onClick={handleShowDatePicker}>{props.title}<ArrowDropDownIcon
					fontSize={'large'}/></div>
				<div className={css.navigation}>
					<div className={css.yesterday}><IconButton color={'primary'} component={Link}
																										 to={props.prevLinkUrl}><ArrowBackIosNewIcon/></IconButton></div>
					<div className={css.today}><IconButton color={'primary'} component={Link}
																								 to={props.centerLinkUrl}>{props.centerLinkTitle}</IconButton></div>
					<div className={css.tomorrow}><IconButton color={'primary'} component={Link}
																										to={props.nextLinkUrl}><ArrowForwardIosIcon/></IconButton></div>
				</div>
				<div className={css.dummy}/>
				<div className={css.subActions}>
					{props.subActionNode}
				</div>
			</div>
			{showDatePicker &&
      <PopDatePicker
          show={showDatePicker}
          anchorEl={anchorRef.current}
          onClose={handleOnCloseDatePicker}
          onChangeDate={handleOnChangeDate}
      />
			}
		</header>
	)
}
