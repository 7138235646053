import React, {ReactNode, useContext, useEffect, useState} from 'react';
import {ShiftDayData} from '../types/ShiftDayData';
import {cloneDeep} from 'lodash';
import {ShiftDayDataContext} from './ShiftDayDataContext';
import {ShiftDataContext} from './ShiftDataContext';
import {format} from 'date-fns';
import Api from '../../general/classes/Api';

type ShiftEditor = {
	tmpShiftDayData: ShiftDayData;
	setTmpShiftDayData: React.Dispatch<React.SetStateAction<ShiftDayData>>
	isEditMode: boolean,
	setEditMode: React.Dispatch<React.SetStateAction<boolean>>,
	showShiftUserSelectionModal: boolean,
	setShowShiftUserSelectionModal: React.Dispatch<React.SetStateAction<boolean>>,
	onChangeTemplate: (templateId: number) => void,
	submit: () => void,
	cancel: () => void,
}

type ShiftEditorContextType = {
	editor: ShiftEditor
}
export const ShiftEditorContext = React.createContext<ShiftEditorContextType>(null as any)

type ShiftEditorProviderProps = {
	initialShiftDayData: ShiftDayData;
	children: ReactNode;
}

function ShiftEditorProvider(props: ShiftEditorProviderProps) {
	const {shiftData} = useContext(ShiftDataContext);
	const {shiftDayResponse} = useContext(ShiftDayDataContext);
	const shiftDayData: ShiftDayData = shiftDayResponse.shiftDayData;

	const [tmpShiftDayData, setTmpShiftDayData] = useState<ShiftDayData>(cloneDeep(shiftDayData));
	const [isEditMode, setEditMode] = useState<boolean>(false);
	const [showShiftUserSelectionModal, setShowShiftUserSelectionModal] = useState<boolean>(false);
	const api = Api.getInstance();

	useEffect(() => {
		if (!isEditMode) {
			setTmpShiftDayData(cloneDeep(shiftDayData));
		}
	}, [shiftDayData])


	const submit = () => {
		setEditMode(false);
		api.patch('/shift/' + shiftData.id + '/day/' + format(shiftDayData.date, 'yyyyMMdd'), editor.tmpShiftDayData);
	}
	const cancel = () => {
		console.log('onCancel');
		setTmpShiftDayData(cloneDeep(shiftDayData));
		setEditMode(false);
	}

	const onChangeTemplate = (templateId: number) => {
		window.confirm('シフトを変更すると時間割が初期化されます。よろしいですか？');
		let template = null;
		shiftData.templates.forEach((t) => {
			if (t.id === templateId) {
				template = t;
			}
		});
		if (template) {
			tmpShiftDayData.template = template;
			tmpShiftDayData.allocations = [];
			setTmpShiftDayData(cloneDeep(tmpShiftDayData));
		}
	}
	const editor: ShiftEditor = {
		tmpShiftDayData,
		setTmpShiftDayData,
		isEditMode,
		setEditMode,
		showShiftUserSelectionModal,
		setShowShiftUserSelectionModal,
		onChangeTemplate,
		submit,
		cancel
	}

	return (
		<ShiftEditorContext.Provider value={{editor}}>
			{props.children}
		</ShiftEditorContext.Provider>
	)
}

export default ShiftEditorProvider;
