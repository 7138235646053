import css from './UnderConstruction.module.scss';
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';

export default function UnderConstruction(props: any) {

	return (
		<div className={css.root}>
			<div className={css.title}>{props.children}</div>
			<div className={css.header}>
				<div className={css.headerIcon}><ConstructionOutlinedIcon fontSize={'large'}/></div>
				<div className={css.headerContent}>現在開発中です</div>
			</div>
		</div>
	)
}
