import css from './ListComponent.module.scss'
import React, {useContext} from 'react';

import {CalendarDataContext} from '../../contexts/CalendarDataContext';
import {EventSearchContext} from '../../contexts/EventSearchContext';
import {EventSearchResult} from '../../hooks/UseEventList';
import {UserTypes} from '../../../auth/types/UserTypes';
import {CalendarUser} from '../../types/CalendarUser';
import {EventUser} from '../../types/EventUser';
import {format} from 'date-fns';
import {Box, Grid, Paper, Typography} from '@mui/material';


export default function ListComponent() {

	const [calendarData] = useContext(CalendarDataContext);
	const result: EventSearchResult = useContext(EventSearchContext);
	console.log(calendarData);

	const humans = calendarData.calendarUsers.filter((calendarUser) => {
		return (calendarUser.user.type === UserTypes.Human)
	})
	// const events = calendarData.calendarUsers.filter((calendarUser) => {
	// 	return (calendarUser.user.type === UserTypes.Event)
	// })
	// const equipments = calendarData.calendarUsers.filter((calendarUser) => {
	// 	return (calendarUser.user.type === UserTypes.Equipment);
	// })
	// const rooms = calendarData.calendarUsers.filter((calendarUser) => {
	// 	return (calendarUser.user.type === UserTypes.Room);
	// })


	const renderItem = (calendarUser: CalendarUser, isLarge: boolean) => {
		const events = result.events.filter((event) => {
			const numMatches = event.eventUsers.filter((eventUser: EventUser) => {
				return (calendarUser.user.id === eventUser.userId)
			}).length;
			return (numMatches > 0)
		})

		const normalEvents = events.filter((event) => {
			return (!event.flag);
		})
		const flagEvents = events.filter((event) => {
			return (event.flag);
		})

		let positionColor = calendarUser.user.position.color;
		if (positionColor.length === 0) {
			positionColor = '#999';
		}


		return (
			<div className={css.user} key={calendarUser.user.id} style={{borderLeftColor: positionColor}}>
				<div className={css.userContainer}>
					<div className={css.userDisplayName}>
						<span>{calendarUser.user.displayName}</span>
					</div>
					<div className={css.userContent}>
						{flagEvents.length > 0 && (
							<div className={css.userFlags}>
								{flagEvents.map((event) => {
									const styles: any = {}
									if (event.flag?.color) {
										styles['color'] = event.flag.color;
									}
									return (
										<div key={event.id} className={css.userFlag}><span style={styles}>{event.subject}</span></div>
									)
								})}
							</div>
						)}
						{normalEvents.length > 0 && (
							<div className={css.userEvents}>
								{normalEvents.map((event) => {
									let startF = 'H:mm';
									let endF = 'H:mm';
									// 日をまたぐ場合は時刻フォーマットを変更
									if (event.startDate.getDate() !== result.start.getDate()) {
										startF = 'd日H:mm';
									}
									if (event.endDate.getDate() !== result.start.getDate()) {
										endF = 'd日H:mm';
									}
									return (
										<div key={event.id} className={css.userEvent}>
											<div className={css.userEventSubject}>{event.subject}</div>
											<div className={css.userEventDateRange}>
												<span className={css.userEventStartDate}>{format(event.startDate, startF)}</span>
												<span className={css.userEventEndDate}><span
													className={css.userEventDateSep}>〜</span>{format(event.endDate, endF)}</span>
											</div>
										</div>
									)
								})}
							</div>

						)}
					</div>
				</div>
			</div>
		)
	}

	return (
		<div className={css.root}>
			<Box padding={1}>
				<div className={css.container}>
					{humans.map((human) => {
						return renderItem(human, true);
					})}
				</div>
				{/*<Grid container spacing={0.5}>*/}
				{/*	{smallCells.map((human) => {*/}
				{/*		return renderItem(human, false);*/}
				{/*	})}*/}
				{/*</Grid>*/}
			</Box>
		</div>
	)
}
