import css from './DefaultLayout.module.scss';
import {Outlet} from 'react-router-dom';
import React from 'react';

export function DefaultLayout() {


	return (
		<>
			<Outlet/>
		</>
	)
}
