import {GridItem} from '../types/GridItem';
import {Layout} from 'react-grid-layout';
import {ShiftDayData} from '../types/ShiftDayData';
import {GridPlaceholder} from '../types/GridPlaceholder';
import {Template} from '../types/Template';
import {GridData} from '../components/ShiftDayGrid';
import {Allocation} from '../types/Allocation';
import {template} from 'lodash';
import uuid from 'react-uuid';

export const gridMaxBooking = 12;
export const gridOffsetY = 3;
export const gridOffsetBottom = 1;


const isColisionX = (a: Layout, b: Layout) => {
	if (a.x >= b.x && (a.x + a.w) <= (b.x + b.w)) {
		return true;
	}
	if (a.x <= b.x && (a.x + a.w) >= (b.x + b.w)) {
		return true;
	}
	if (a.x > b.x && a.x < (b.x + b.w)) {
		return true;
	}
	if ((a.x + a.w) > b.x && (a.x + a.w) < (b.x + b.w)) {
		return true;
	}
	return false;
}


export function adjustMultiBooking(gridItems: GridItem[]) {
	gridItems.forEach((gridItem) => {
		gridItem.layout.h = -1;
	})
	gridItems.forEach((gridItem: GridItem) => {
		if (gridItem.layout.h !== -1) {
			return;
		}

		let shiftPosY = Math.floor(gridItem.layout.y / gridMaxBooking);

		const collisions = gridItems.filter((targetGridItem: GridItem) => {
			const targetShiftPosY = Math.floor(targetGridItem.layout.y / gridMaxBooking);
			if (targetGridItem.layout.i === gridItem.layout.i) {
				return false;
			}
			if (targetShiftPosY !== shiftPosY) {
				return false;
			}
			return isColisionX(targetGridItem.layout, gridItem.layout);
		});

		if (collisions.length >= 1) {
			let posY = shiftPosY * gridMaxBooking + gridOffsetY;
			const itemHeight = (gridMaxBooking - gridOffsetY - gridOffsetBottom) / (collisions.length + 1);

			gridItem.layout.h = itemHeight;
			gridItem.layout.y = posY;
			gridItem.numCollision = collisions.length;
			posY += itemHeight;
			collisions.forEach((collisionShift) => {
				collisionShift.layout.h = itemHeight;
				collisionShift.layout.y = posY;
				collisionShift.numCollision = collisions.length;
				posY += itemHeight;
			})
		} else {
			gridItem.layout.h = gridMaxBooking - gridOffsetY - gridOffsetBottom;
			gridItem.layout.y = shiftPosY * gridMaxBooking + gridOffsetY;
			gridItem.numCollision = 0;
		}
	})

	gridItems.forEach((gridItem: GridItem) => {
		gridItem.layout.i = uuid();
	});

}


export const shiftDayData2GridData = (shiftDayData: ShiftDayData): GridData => {
	const maxBooking = 12;
	const gridPlaceholders: GridPlaceholder[] = [];
	const gridItems: GridItem[] = [];
	if (shiftDayData.template !== null) {
		const template: Template = shiftDayData.template;
		shiftDayData.template.roles.forEach((role, roleIndex) => {
			role.placeholders.forEach((placeholder) => {

				const x = (placeholder.startMin - template.startMin) / template.intervalMin;
				const w = (placeholder.endMin - placeholder.startMin) / template.intervalMin;

				const layout: Layout = {
					static: true,
					y: roleIndex * maxBooking,
					h: maxBooking,
					i: placeholder.id.toString(),
					x: x,
					w: w,
					minH: 1,
					maxH: maxBooking,
					minW: 1,
					maxW: 16
				}
				gridPlaceholders.push({
					name: placeholder.name,
					layout: layout
				})
			})
		})
		shiftDayData.allocations.forEach((allocation, index) => {

			const x = (allocation.startMin - template.startMin) / template.intervalMin;
			const w = (allocation.endMin - allocation.startMin) / template.intervalMin;
			const h = 8;
			let y = 0;

			shiftDayData.template.roles.forEach((role, roleIndex) => {
				if (allocation.roleId === role.id) {
					y = roleIndex * gridMaxBooking;
				}
			});

			const layout: Layout = {
				i: uuid(),
				x: x,
				y: y,
				w: w,
				h: h,
				static: false
			}
			gridItems.push({
				layout,
				user: allocation.user,
				name: allocation.user.displayName,
				id: allocation.id,
				placeholderId: allocation.placeholderId,
				numCollision: 0,
				freeTime: false
			})
		})

	}
	return {
		items: gridItems,
		placeholders: gridPlaceholders
	}
}

export const gridItem2Allocation = (gridItems: GridItem[], baseShiftDayData: ShiftDayData): Allocation[] => {
	const allocations: Allocation[] = [];
	gridItems.forEach((gridItem) => {
		const startMin = gridItem.layout.x * baseShiftDayData.template.intervalMin + baseShiftDayData.template.startMin;
		const endMin = startMin + gridItem.layout.w * baseShiftDayData.template.intervalMin;
		const roleNumber = Math.floor(gridItem.layout.y / gridMaxBooking);
		const role = baseShiftDayData.template.roles[roleNumber];
		const allocation: Allocation = {
			id: (gridItem.id) ? gridItem.id : 0,
			placeholderId: gridItem.placeholderId,
			startMin: startMin,
			endMin: endMin,
			userId: gridItem.user.id,
			roleId: role.id,
			user: gridItem.user
		};
		allocations.push(allocation);
	})
	return allocations;
}
