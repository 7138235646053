import {CalendarEvent} from '../../types/CalendarEvent';
import {CalendarUser} from '../../types/CalendarUser';
import {Button} from '@mui/material';
import React, {useContext} from 'react';
import {Flag} from '../../types/Flag';
import {EventUser} from '../../types/EventUser';
import {CalendarEventRequest} from '../../types/CalendarEventRequest';
import {CalendarDataContext} from '../../contexts/CalendarDataContext';
import {UserCellDialogEditContext} from './UserCellDialogEdit';
import Api from '../../../general/classes/Api';
import {ENDPOINTS} from '../../../general/constants/ENDPOINTS';
import {FlashMessageContext} from "../../../general/contexts/FlashMessageContext";


type EventFlagEditorProps = {
    events: CalendarEvent[],
    calendarUser: CalendarUser,
    date: Date,
    onUpdate: () => void
}


export default function EventFlagEditor(props: EventFlagEditorProps) {
    const [, setStatus] = useContext(UserCellDialogEditContext);
    const [calendarData] = useContext(CalendarDataContext);
    const {flashMessage, flashErrorHandler} = useContext(FlashMessageContext);

    const api = Api.getInstance();

    const setUploading = (value: boolean) => {
        setStatus({
            loading: value
        })
    }
    const onClickFlag = (flag: Flag, eventId: number) => {

        if (!eventId) {
            const eventUser: EventUser = {
                userId: props.calendarUser.user.id,
                eventId: 0,
                isAttendee: false,
                isOrganizer: true
            }

            const event: CalendarEventRequest = {
                id: 0,
                calendarId: calendarData.id,
                eventUsers: [eventUser],
                flagId: flag.id,
                subject: flag.name,
                startDate: new Date(props.date),
                endDate: new Date(props.date),
                isAllDay: false,
                bodyContent: '',
                locationId: null
            }

            if (flag.isBlock) {
                event.startDate.setHours(flag.blockStartTime.getHour());
                event.startDate.setMinutes(flag.blockStartTime.getMin());
                event.startDate.setSeconds(flag.blockStartTime.getSec());
                event.endDate.setHours(flag.blockEndTime.getHour());
                event.endDate.setMinutes(flag.blockEndTime.getMin());
                event.endDate.setSeconds(flag.blockEndTime.getSec());
            } else {
                event.startDate.setHours(0);
                event.startDate.setMinutes(0);
                event.startDate.setSeconds(0);
                event.endDate.setHours(0);
                event.endDate.setMinutes(0);
                event.endDate.setSeconds(0);
            }

            setUploading(true);
            api.post(ENDPOINTS.calendar.event, {event}).then((resp) => {
                props.onUpdate();
            }).catch((e) => {
                flashErrorHandler(e);
            }).finally(()=>{
                setUploading(false);
            })
        } else {
            setUploading(true);
            api.delete(ENDPOINTS.calendar.event + '/' + eventId).then((resp) => {
                props.onUpdate();
            }).catch((e) => {
                flashErrorHandler(e);
            }).finally(()=>{
                setUploading(false);
            })
        }


    }


    return (
        <>
            {calendarData.flags.filter((flag) => {
                // 使用できるフラグをフィルタ
                let result = true;
                // 使用制限がついている場合
                if (flag.isRestricted) {
                    // 許可ユーザーリストに含まれているか
                    const hasPermission = flag.restrictedUserIds.find((userId) => {
                        return userId === props.calendarUser.user.id;
                    })
                    if (!hasPermission) {
                        // 含まれていないので不許可
                        result = false;
                        props.events.forEach((event) => {
                            if (event.flag?.code === flag.code) {
                                // ただし、既にフラグが使用されているのであれば、特別に許可する
                                result = true;
                            }
                        })
                    }
                }
                return result;
            }).map((flag) => {
                let used = false;
                let eventId = 0;
                props.events.forEach((event) => {
                    if (event.flag?.code === flag.code) {
                        used = true;
                        eventId = event.id;
                    }
                })
                return (
                    <Button
                        key={flag.code}
                        variant={(used) ? 'contained' : 'outlined'}
                        color={(used) ? 'primary' : 'inherit'}
                        onClick={() => {
                            onClickFlag(flag, eventId)
                        }}
                        sx={{
                            m: 0.5
                        }}
                    >{flag.name}
                    </Button>
                )
            })}
        </>


    )
}
