import React, {Dispatch, SetStateAction, useEffect, useState} from "react";

type LayoutStatus = {
    appBarHeight: number
}

type LayoutContextType = [
    status: LayoutStatus,
    setStatus: Dispatch<SetStateAction<LayoutStatus>>
]

export const LayoutContext = React.createContext<LayoutContextType>(null as any)

export default function LayoutProvider(props: any) {

    const defaultLayoutStatus: LayoutStatus = {
        appBarHeight: 0
    }
    const [status, setStatus] = useState<LayoutStatus>(defaultLayoutStatus);

    useEffect(() => {
        document.documentElement.style.setProperty('--layout-appbar-height', status.appBarHeight.toString() + 'px');
    })

    return (
        <LayoutContext.Provider value={[status, setStatus]}>
            {props.children}
        </LayoutContext.Provider>
    )
}

