import {CalendarData} from '../types/CalendarData';
import React, {ReactNode} from 'react';
import useCalendarData from '../hooks/UseCalendarData';
import SpinnerContainer from '../../general/components/SpinnerContainer';

type CalendarDataContextType = [
    calendarData: CalendarData
]
export const CalendarDataContext = React.createContext<CalendarDataContextType>(null as any)

type CalendarDataProviderProps = {
    calendarId: number,
    children: ReactNode
}

export default function CalendarDataProvider(props: CalendarDataProviderProps) {

    const [calendarData, calendarDataLoading] = useCalendarData(props.calendarId);

    return (
        <CalendarDataContext.Provider value={[calendarData]}>
            <SpinnerContainer loading={calendarDataLoading}>
                {calendarData && props.children}
            </SpinnerContainer>
        </CalendarDataContext.Provider>
    )
}

