import Api from "../../general/classes/Api";
import {ENDPOINTS} from "../../general/constants/ENDPOINTS";
import {User} from "../types/User";
import {useEffect, useState} from "react";


export default function useUserList() {

    const [userList, setUserList] = useState<User[] | null>(null);
    const [userListLoading, setUserListLoading] = useState<boolean>(true);

    useEffect(() => {
        const api = Api.getInstance();
        api.get<User[]>(ENDPOINTS.user.list).then((response) => {
            setUserList(response);
        }).finally(() => {
            setUserListLoading(false);
        });
    }, [])

    return {userList, userListLoading};
}
