import {useContext, useEffect, useState} from 'react';
import {CalendarData} from '../types/CalendarData';
import {Time} from '../../general/classes/Time';
import Api, {ApiError} from '../../general/classes/Api';
import {ENDPOINTS} from '../../general/constants/ENDPOINTS';
import {FlashMessageContext} from "../../general/contexts/FlashMessageContext";
import {useNavigate} from "react-router-dom";

export default function useCalendarData(calendarId: number): [CalendarData, boolean] {
    const [calendarData, setCalendarData] = useState(null as any);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const {flashErrorHandler} = useContext(FlashMessageContext);

    const api = Api.getInstance();

    const get = () => {
        setLoading(true);
        api.get<CalendarData>(ENDPOINTS.calendar.index + '/' + calendarId).then((data) => {
            data.flags.forEach((flag) => {
                flag.blockStartTime = new Time(flag.blockStartTime as any);
                flag.blockEndTime = new Time(flag.blockEndTime as any);
            })
            setCalendarData(data);
        }).catch((e) => {
            flashErrorHandler(e);
            navigate('/');
        }).finally(() => {
            setLoading(false);
        })
    }
    //
    useEffect(() => {
        get()
    }, [calendarId])

    return [calendarData, loading];
}
