import axios, {Axios, AxiosError, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig} from 'axios';
import {CONFIG} from '../constants/CONFIG';
import {ApiResponse} from "../types/ApiResponse";
import {FlashMessage} from "../contexts/FlashMessageContext";


export class ApiError extends Error {
    public isSoftError: boolean;
    public axiosError: AxiosError | null;
    public reason: string;
    public status: number | undefined;

    constructor(message: string) {
        super(message);
        this.isSoftError = false;
        this.axiosError = null;
        this.status = undefined;
        this.reason = '不明な理由で失敗しました';
    }
}

class Api {
    private static _instance: Api;
    private axiosInstance: Axios;
    private bearerToken: string;

    constructor() {
        this.axiosInstance = axios.create({
            baseURL: CONFIG.apiBaseUrl
        });
        this.axiosInstance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
            if (config.headers !== undefined) {
                if (this.bearerToken && this.bearerToken.length > 0) {
                    config.headers.Authorization = `Bearer ${this.bearerToken}`;
                }
            }
            return config;
        })
        this.axiosInstance.interceptors.response.use(
            (response: AxiosResponse<ApiResponse<any>>) => {
                // console.log('inter', response);
                if (response.data.success) {
                    return response.data.payload;
                } else {
                    const apiError = new ApiError(response.data.reason);
                    apiError.status = response.status;
                    apiError.message = response.data.reason;
                    apiError.reason = response.data.reason;
                    apiError.axiosError = null;
                    apiError.isSoftError = true;
                    throw apiError;
                }
            }
        )
        // this.setErrorInterceptor();
        this.bearerToken = '';
    }

    public static getInstance() {
        if (!this._instance) {
            this._instance = new Api();
        }
        return this._instance;
    }

    public setToken(token: string) {
        this.bearerToken = token;
    }

    protected errorHandler = (e: Error): ApiError => {
        // console.log('errorHandler', e);
        if (e instanceof AxiosError) {
            const apiError = new ApiError(e.message);
            apiError.status = e.status;
            apiError.axiosError = e;
            if (e.response && e.response.data && e.response.data.hasOwnProperty('reason')) {
                apiError.reason = e.response.data.reason;
                apiError.isSoftError = true;
            } else {
                apiError.isSoftError = false;
            }
            return apiError;
        } else {
            return new ApiError(e.message);
        }
    }

    public get<T>(path: string, params?: object | null): Promise<T> {

        return new Promise<T>((resolve, reject) => {
            this.axiosInstance.get<ApiResponse<T>>(path, {
                params
            }).then((response) => {
                resolve(response as T);
            }).catch((e) => {
                reject(this.errorHandler(e));
            })
        })

    }

    public async post<T>(path: string, params: object): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            this.axiosInstance.post<ApiResponse<T>>(path, params)
                .then((response) => {
                    resolve(response as T);
                })
                .catch((e) => {
                    reject(e);
                });
        })
    }

    public async patch<T>(path: string, params: object): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            this.axiosInstance.patch<ApiResponse<T>>(path, params)
                .then((response) => {
                    resolve(response as T);
                })
                .catch((e) => {
                    reject(this.errorHandler(e));
                });
        })
    }

    public async delete<T>(path: string): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            this.axiosInstance.delete<ApiResponse<T>>(path)
                .then((response) => {
                    resolve(response as T);
                })
                .catch((e) => {
                    reject(this.errorHandler(e));
                });
        })
    }

}

export default Api;
