import React, {useContext, useRef, useState} from 'react';
import {CalendarUser} from '../../types/CalendarUser';
import {CalendarEvent} from '../../types/CalendarEvent';
import css from './UserCell.module.scss'
import CellPopover from './CellPopover';
import {EventUser} from '../../types/EventUser';
import {CalendarEventRequest} from '../../types/CalendarEventRequest';
import {MonthlyCalendarSettingsContext} from '../../contexts/MonthlyCalendarSettingsContext';
import {CalendarDataContext} from '../../contexts/CalendarDataContext';
import Api, {ApiError} from '../../../general/classes/Api';
import {ENDPOINTS} from '../../../general/constants/ENDPOINTS';
import {FlashMessageContext} from '../../../general/contexts/FlashMessageContext';
import useFlash from "../../../general/hooks/UseFlash";


type UserCellProps = {
    calendarUser: CalendarUser,
    date: Date;
    events: CalendarEvent[]
}

export default function UserCell(props: UserCellProps) {

    const [calendarData] = useContext(CalendarDataContext);
    const [showDetail, setShowDetail] = useState<boolean>(false);
    const cellRef = useRef(null);
    const [calendarSettings, setCalendarSettings] = useContext(MonthlyCalendarSettingsContext);
    const {flash} = useFlash();
    const api = Api.getInstance();

    const setUploading = (value: boolean) => {
        const newSettings = {...calendarSettings};
        newSettings.uploading = value;
        setCalendarSettings(newSettings);
    }


    let maxStrLen = 1;
    let labels: string[] = [];
    let otherLabels: string[] = [];
    let backgroundColor = '';

    // 表示は2つまで。あふれたイベント数は別途表示
    // フラグイベントを優先表示
    props.events.forEach((event) => {
        if (event.flag) {
            // ラベル設定無し(背景色のみ)の場合はラベル表示に入れない
            if (event.flag.label.length > 0) {
                if (labels.length < 2) {
                    labels.push(event.flag.label);
                } else {
                    otherLabels.push(event.flag.label);
                }
            }
            // フラグには色設定があるため、記録しておく。重複指定の場合は上書き。
            if (event.flag.backgroundColor) {
                backgroundColor = event.flag.backgroundColor;
            }
        }
    })
    props.events.forEach((event) => {
        if (!event.flag) {
            if (labels.length < 2 || props.events.length === 3) {
                labels.push(event.subject);
            } else {
                otherLabels.push(event.subject);
            }
        }
    })


    const style: React.CSSProperties = {
        backgroundColor: backgroundColor
    };

    const weekNumber = props.date.getDay();

    const toggleFlag = () => {

        // 同種のラベルを検出
        const currentEvent: CalendarEvent | undefined = props.events.find((event) => {
            return (event.flag && event.flag.code === calendarSettings.directLabelFlag?.code);
        })

        if (!currentEvent && calendarSettings.directLabelFlag) {
            const eventUser: EventUser = {
                userId: props.calendarUser.user.id,
                eventId: 0,
                isAttendee: false,
                isOrganizer: true
            }
            const flag = calendarSettings.directLabelFlag;

            const event: CalendarEventRequest = {
                id: 0,
                calendarId: calendarData.id,
                eventUsers: [eventUser],
                flagId: flag.id,
                subject: flag.name,
                startDate: new Date(props.date),
                endDate: new Date(props.date),
                isAllDay: false,
                bodyContent: '',
                locationId: null
            }

            if (flag.isBlock) {
                event.startDate.setHours(flag.blockStartTime.getHour());
                event.startDate.setMinutes(flag.blockStartTime.getMin());
                event.startDate.setSeconds(flag.blockStartTime.getSec());
                event.endDate.setHours(flag.blockEndTime.getHour());
                event.endDate.setMinutes(flag.blockEndTime.getMin());
                event.endDate.setSeconds(flag.blockEndTime.getSec());
            } else {
                event.startDate.setHours(0);
                event.startDate.setMinutes(0);
                event.startDate.setSeconds(0);
                event.endDate.setHours(0);
                event.endDate.setMinutes(0);
                event.endDate.setSeconds(0);
            }

            setUploading(true);
            api.post(ENDPOINTS.calendar.event, {event}).then((resp) => {
            }).catch((e: any) => {
                if (e instanceof ApiError) {
                    flash.error(e.reason);
                }
            }).finally(() => {
                setUploading(false);
            })
        } else if (currentEvent) {
            setUploading(true);
            api.delete(ENDPOINTS.calendar.event + '/' + currentEvent.id).then((resp) => {
            }).catch((e: any) => {
                if (e instanceof ApiError) {
                    flash.error(e.reason);
                }
            }).finally(() => {
                setUploading(false);
            })
        }

    }

    const onClickCell = () => {
        if (calendarSettings.directLabelMode && calendarSettings.directLabelFlag) {
            toggleFlag();
        } else {
            setShowDetail(true);
        }
    }

    return (
        <>
            <td className={css.userCell}
                ref={cellRef}
                data-week-number={weekNumber}
                style={style}
                data-clickable="1"
                data-active={(showDetail) ? 1 : 0}
                onClick={() => {
                    onClickCell()
                }}>

                <div className={css.labels} data-num-labels={labels.length}>
                    {labels.map((text, index) => {
                        return (
                            <div className={css.label} key={index} data-strlen={text.length}>{text}</div>
                        )
                    })}
                    {(otherLabels.length > 0) &&
                        <div className={css.label}>他<span className={css.count}>{otherLabels.length}</span>件</div>}
                </div>
            </td>
            {showDetail &&
                <CellPopover show={showDetail} anchorEl={cellRef.current} onClose={() => {
                    setShowDetail(false)
                }} date={props.date} calendarUser={props.calendarUser} events={props.events}/>
            }
        </>


    )

}
