import {useContext} from "react";
import {FlashMessage, FlashMessageContext} from "../contexts/FlashMessageContext";

export default function useFlash() {
    const {flashMessage, saveMessage} = useContext(FlashMessageContext);

    const flash = {
        info: (message: string, duration = 6000, nextSession = false) => {
            const m: FlashMessage = {
                message,
                duration,
                type: 'info'
            }
            if( nextSession ){
                saveMessage(m);
            }else{
                flashMessage(m);
            }
        },
        error: (message: string, duration = 6000, nextSession = false) => {
            const m: FlashMessage = {
                message,
                duration,
                type: 'error'
            }
            if( nextSession ){
                saveMessage(m);
            }else{
                flashMessage(m);
            }
        },
        success: (message: string, duration = 6000, nextSession = false) => {
            const m: FlashMessage = {
                message,
                duration,
                type: 'success'
            }
            if( nextSession ){
                saveMessage(m);
            }else{
                flashMessage(m);
            }
        },
        warning: (message: string, duration = 6000, nextSession = false) => {
            const m: FlashMessage = {
                message,
                duration,
                type: 'warning'
            }
            if( nextSession ){
                saveMessage(m);
            }else{
                flashMessage(m);
            }
        },
    }

    return {flash};
}
