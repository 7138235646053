import React, {useContext, useEffect, useMemo, useState} from 'react';
import {CalendarUser} from '../../types/CalendarUser';
import {CalendarEvent} from '../../types/CalendarEvent';
import css from './EventDialog.module.scss';
import {
    Box, Button, Card, CardContent, Checkbox,
    Collapse,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    FormControl, FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    MenuItem,
    TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {CalendarDataContext} from '../../contexts/CalendarDataContext';
import {format} from 'date-fns';
import {UserTypes} from '../../../auth/types/UserTypes';
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import {SubmitHandler, useForm, useFieldArray} from 'react-hook-form';
import ReactHookSelect from '../../../general/components/ReactHookSelect';
import ReactHookCheckbox from '../../../general/components/ReactHookCheckbox';
import {EventUser} from '../../types/EventUser';
import {CONFIG} from '../../../general/constants/CONFIG';
import {LoadingButton} from '@mui/lab';
import Api from '../../../general/classes/Api';
import {ENDPOINTS} from '../../../general/constants/ENDPOINTS';
import {ja} from 'date-fns/locale';
import {FlashMessageContext} from "../../../general/contexts/FlashMessageContext";

type EventDialogProps = {
    defaultDate: Date,
    defaultOrganizer: CalendarUser,
    event?: CalendarEvent,
    submitHandler?: () => void,
    onClose: () => void,
}

type CheckBoxCounts = {
    human: number;
    equipment: number;
    room: number;
}

type Inputs = {
    eventId: number,
    subject: string,
    startDateString: string,
    endDateString: string,
    organizerId: number,
    isAllDay: boolean,
    eventUsers: { userId: number, displayName: string, checked: boolean, type: string }[]
}


export default function EventDialog(props: EventDialogProps) {

    const [initialized, setInitialized] = useState(false);
    const [forceUpdate, setForceUpdate] = useState<number>(0);
    const [calendarData] = useContext(CalendarDataContext);
    const [uploading, setUploading] = useState<boolean>(false);
    const [openAttendee, setOpenAttendee] = useState<boolean>(false);
    const [openEquipment, setOpenEquipment] = useState<boolean>(false);
    const [openRoom, setOpenRoom] = useState<boolean>(false);
    const {flashErrorHandler} = useContext(FlashMessageContext);

    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        control,
        formState: {errors},
    } = useForm<Inputs>()
    const {fields: eventUserFields, append: eventUserAppend, remove: eventUserRemove} = useFieldArray({
        control,
        name: "eventUsers",
    });
    const api = Api.getInstance();


    const humanCalendarUsers = useMemo<CalendarUser[]>(() => {
        return calendarData.calendarUsers.filter((calendarUser) => {
            return (calendarUser.user.type === UserTypes.Human || calendarUser.user.type === UserTypes.Event);
        });
    }, [calendarData])


    useEffect(() => {
        eventUserRemove();
        if (props.event) {
            let organizerId = 0;
            calendarData.calendarUsers.forEach((calendarUser) => {
                let checked = false;
                let isOrganizer = false;
                props.event?.eventUsers.forEach((eventUser) => {
                    if (calendarUser.user.id === eventUser.userId) {
                        checked = true;
                        isOrganizer = eventUser.isOrganizer;
                    }
                })

                eventUserAppend({
                    userId: calendarUser.user.id,
                    displayName: calendarUser.user.displayName,
                    checked,
                    type: calendarUser.user.type
                })

                if (isOrganizer) {
                    if (calendarUser.user.type === UserTypes.Equipment || calendarUser.user.type === UserTypes.Room) {
                        organizerId = 0;
                    } else {
                        organizerId = calendarUser.user.id;
                    }
                }
            })

            setValue('eventId', props.event.id);
            setValue('startDateString', format(props.event.startDate, 'yyyy-MM-dd HH:mm'));
            setValue('endDateString', format(props.event.endDate, 'yyyy-MM-dd HH:mm'));
            setValue('subject', props.event.subject);
            setValue('organizerId', organizerId);
            setValue('isAllDay', (props.event.isAllDay))
        } else {
            calendarData.calendarUsers.forEach((calendarUser) => {

                let checked = false;
                if (calendarUser.user.id === props.defaultOrganizer.user.id) {
                    checked = true;
                }

                eventUserAppend({
                    userId: calendarUser.user.id,
                    displayName: calendarUser.user.displayName,
                    checked: checked,
                    type: calendarUser.user.type
                })
            })


            setValue('subject', '')
            const startDate = (new Date(props.defaultDate)).setHours(8);
            const endDate = (new Date(props.defaultDate)).setHours(9);
            setValue('eventId', 0);
            setValue('startDateString', format(startDate, 'yyyy-MM-dd HH:mm'));
            setValue('endDateString', format(endDate, 'yyyy-MM-dd HH:mm'));
            setValue('isAllDay', false);
            if (props.defaultOrganizer.user.type === UserTypes.Equipment || props.defaultOrganizer.user.type === UserTypes.Room) {
                setValue('organizerId', 0);
            } else {
                setValue('organizerId', props.defaultOrganizer.user.id);
            }
        }
        setInitialized(true);

        return () => {
            setInitialized(false);
        }

    }, [calendarData])


    const validateDateString = (dateString: string) => {
        const date = new Date(dateString);
        if (date > new Date('2038/12/31')) {
            return '最大日時を超えています'
        }
        if (date < new Date('2023/1/1')) {
            return '最小日時を下回っています'
        }
        return undefined;
    }

    const validateDateRange = () => {
        const startDate = new Date(getValues('startDateString'));
        let endDate = new Date(getValues('endDateString'));
        if (startDate > endDate) {
            endDate = new Date(startDate);
            endDate.setTime(endDate.getTime() + 1000 * 60 * 30);
            setValue('endDateString', format(endDate, 'yyyy-MM-dd HH:mm'));
        }
    }

    const validateOrganizerId = (id: number) => {
        if (id === 0) {
            return '必須項目です'
        }
        return undefined;
    }
    const organizerAutoCheck = () => {
        eventUserFields.forEach((field) => {
            if (field.userId === getValues('organizerId')) {
                field.checked = true;
            }
        })
    }


    const onSubmit: SubmitHandler<Inputs> = (data) => {
        const eventUsers: EventUser[] = [];

        eventUserFields.forEach((field) => {
            if (field.checked) {
                let isOrganizer = false;
                let isAttendee = true;

                if (field.userId === data.organizerId) {
                    isOrganizer = true;
                    isAttendee = false;
                }

                eventUsers.push({
                    userId: field.userId,
                    isOrganizer,
                    isAttendee,
                    eventId: data.eventId,
                })
            }
        })
        if (data.isAllDay) {
            data.startDateString = format(props.defaultDate, 'yyyy-MM-dd 00:00');
            data.endDateString = format(props.defaultDate, 'yyyy-MM-dd 23:59');
        }


        const event: CalendarEvent = {
            id: data.eventId,
            calendarId: calendarData.id,
            subject: data.subject,
            startDate: new Date(data.startDateString),
            endDate: new Date(data.endDateString),
            isAllDay: data.isAllDay,
            flag: null,
            eventUsers: eventUsers,
            bodyContent: '',
            locationId: null
        }
        setUploading(true);
        if (event.id === 0) {
            api.post(ENDPOINTS.calendar.event, {event}).then((resp) => {
                props.onClose();
            }).catch((e) => {
                flashErrorHandler(e);
            }).finally(() => {
                setUploading(false);
            })
        } else {
            api.patch(ENDPOINTS.calendar.event + '/' + event.id, {event}).then((resp) => {
                props.onClose();
            }).catch((e) => {
                flashErrorHandler(e);
            }).finally(() => {
                setUploading(false);
            })
        }

    }

    const handleClose = (event: any, reason: string) => {
    }

    organizerAutoCheck();
    const checkBoxCounts = {human: 0, equipment: 0, room: 0};

    eventUserFields.forEach((field) => {
        // if (field.userId !== getValues('organizerId')) {
        if (field.type === UserTypes.Human && field.checked) {
            checkBoxCounts.human++;
        }
        if (field.type === UserTypes.Equipment && field.checked) {
            checkBoxCounts.equipment++;
        }
        if (field.type === UserTypes.Room && field.checked) {
            checkBoxCounts.room++;
        }
        // }
    })
    const defaultAlldayLabel = '全日 ' + format(props.defaultDate, 'yyyy/MM/dd (E)', {locale: ja}) + '';


    return (
        <>
            <Dialog
                fullScreen={false}
                open={true}
                fullWidth={true}
                maxWidth={'sm'}
                disableEscapeKeyDown={true}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    予定を作成
                    <IconButton
                        aria-label="close"
                        onClick={props.onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon/>
                    </IconButton>
                </DialogTitle>
                {initialized &&
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <DialogContent>
                            {/*件名*/}
                            <FormControl fullWidth sx={{mb: 3}}>
                                <TextField
                                    id='form-input-subject'
                                    error={!!(errors.subject)}
                                    label="件名"
                                    {...register("subject", {
                                        required: '必須項目です'
                                    })}
                                    helperText={errors.subject?.message}
                                />
                            </FormControl>

                            {/*全日*/}
                            <FormControl sx={{mb: 1}} fullWidth>
                                <ReactHookCheckbox
                                    key={'isAllDay'}
                                    checked={getValues('isAllDay')}
                                    onChange={(e) => {
                                        setValue('isAllDay', e.target.checked)
                                        setForceUpdate(forceUpdate + 1);
                                    }}
                                    name={defaultAlldayLabel} control={control} label={defaultAlldayLabel}
                                    disable={false}/>
                            </FormControl>
                            {/*期間*/}
                            {!getValues('isAllDay') &&
                                <FormControl sx={{mb: 3}}>
                                    <Grid container alignItems={'center'}>
                                        <Box sx={{mr: 1}}>
                                            <div><input
                                                style={{fontSize: '16px'}}
                                                type={'datetime-local'}
                                                step={300}
                                                {...register('startDateString', {
                                                    required: '必須項目です',
                                                    validate: {
                                                        message: validateDateString
                                                    },
                                                    onBlur: (v) => {
                                                        validateDateRange();
                                                    },
                                                })}
                                            />
                                            </div>
                                            {errors.startDateString &&
                                                <div
                                                    className={css.errorMessage}>{errors.startDateString?.message}</div>}
                                        </Box>
                                        <Box sx={{mr: 1, ml: 1}}>〜</Box>
                                        <div>
                                            <div><input
                                                style={{fontSize: '16px'}} type={'datetime-local'}
                                                {...register('endDateString', {
                                                    required: '必須項目です',
                                                    validate: {
                                                        message: validateDateString
                                                    },
                                                    onBlur: (v) => {
                                                        validateDateRange();
                                                    },
                                                })}
                                            /></div>
                                            {errors.endDateString &&
                                                <div className={css.errorMessage}>{errors.endDateString?.message}</div>}
                                        </div>
                                    </Grid>
                                </FormControl>
                            }
                            {/*主催者*/}
                            <FormControl sx={{mb: 3}} fullWidth>
                                <ReactHookSelect
                                    name={'organizerId'}
                                    control={control}
                                    label={'主催者'}
                                    error={!!(errors.organizerId)}
                                    helperText={(errors.organizerId) ? errors.organizerId.message : ''}
                                    rules={{
                                        validate: {
                                            message: validateOrganizerId
                                        }
                                    }}
                                    onChange={(e) => {
                                        organizerAutoCheck();
                                        setForceUpdate(forceUpdate + 1);
                                    }}>
                                    <MenuItem
                                        key={0}
                                        value={0}
                                    >選択ください</MenuItem>
                                    {humanCalendarUsers.map((calendarUser) => {
                                        return <MenuItem
                                            key={calendarUser.user.id}
                                            value={calendarUser.user.id}
                                        >{calendarUser.user.displayName}</MenuItem>;
                                    })}
                                </ReactHookSelect>
                            </FormControl>
                            {/*参加者*/}
                            <Box>
                                <Button sx={{color: "#333"}} size="large" onClick={() => {
                                    setOpenAttendee(!openAttendee)
                                }}>参加者 ({checkBoxCounts.human}){openAttendee ? <ExpandLess/> :
                                    <ExpandMore/>}</Button>
                            </Box>
                            <Collapse in={openAttendee} timeout="auto" unmountOnExit>
                                <Card variant="outlined" sx={{mb: 3}}>
                                    <CardContent>
                                        <FormControl fullWidth>
                                            <FormGroup aria-label="position" row>
                                                {eventUserFields.filter((field) => {
                                                    return (field.type === UserTypes.Human || field.type === UserTypes.Event);
                                                }).map((field, index) => {
                                                    return (
                                                        <ReactHookCheckbox
                                                            key={field.id}
                                                            checked={field.checked}
                                                            onChange={(e) => {
                                                                field.checked = e.target.checked;
                                                                setForceUpdate(forceUpdate + 1);
                                                            }}
                                                            name={field.displayName} control={control}
                                                            label={field.displayName}
                                                            disable={(getValues('organizerId') === field.userId)}/>
                                                    )
                                                })}
                                            </FormGroup>
                                        </FormControl>
                                    </CardContent>
                                </Card>
                            </Collapse>
                            {/*備品*/}
                            <Box>
                                <Button sx={{color: "#333"}} size="large" onClick={() => {
                                    setOpenEquipment(!openEquipment)
                                }}>使用する備品 ({checkBoxCounts.equipment}){openEquipment ? <ExpandLess/> :
                                    <ExpandMore/>}</Button>
                            </Box>
                            <Collapse in={openEquipment} timeout="auto" unmountOnExit>
                                <Card variant="outlined" sx={{mb: 3}}>
                                    <CardContent>
                                        <FormControl fullWidth>
                                            <FormGroup aria-label="position" row>
                                                {eventUserFields.filter((field) => {
                                                    return (field.type === UserTypes.Equipment);
                                                }).map((field, index) => {
                                                    return (
                                                        <ReactHookCheckbox
                                                            key={field.id}
                                                            checked={field.checked}
                                                            onChange={(e) => {
                                                                field.checked = e.target.checked;
                                                                setForceUpdate(forceUpdate + 1);
                                                            }}
                                                            name={field.displayName} control={control}
                                                            label={field.displayName}
                                                            disable={(getValues('organizerId') === field.userId)}/>
                                                    )
                                                })}
                                            </FormGroup>
                                        </FormControl>
                                    </CardContent>
                                </Card>
                            </Collapse>
                            {/*使用する会議室*/}
                            <Box>
                                <Button sx={{color: "#333"}} size="large" onClick={() => {
                                    setOpenRoom(!openRoom)
                                }}>使用する会議室 ({checkBoxCounts.room}){openRoom ? <ExpandLess/> :
                                    <ExpandMore/>}</Button>
                            </Box>
                            <Collapse in={openRoom} timeout="auto" unmountOnExit>
                                <Card variant="outlined" sx={{mb: 3}}>
                                    <CardContent>
                                        <FormControl fullWidth>
                                            <FormGroup aria-label="position" row>
                                                {eventUserFields.filter((field) => {
                                                    return (field.type === UserTypes.Room);
                                                }).map((field, index) => {
                                                    return (
                                                        <ReactHookCheckbox
                                                            key={field.id}
                                                            checked={field.checked}
                                                            onChange={(e) => {
                                                                field.checked = e.target.checked;
                                                                setForceUpdate(forceUpdate + 1);
                                                            }}
                                                            name={field.displayName} control={control}
                                                            label={field.displayName}
                                                            disable={(getValues('organizerId') === field.userId)}/>
                                                    )
                                                })}
                                            </FormGroup>
                                        </FormControl>
                                    </CardContent>
                                </Card>
                            </Collapse>
                        </DialogContent>
                        <DialogActions>
                            <LoadingButton loading={uploading} color={'primary'} variant={'contained'} type={'submit'}>
                                保存
                            </LoadingButton>
                        </DialogActions>
                    </form>
                }

            </Dialog>
        </>
    )

}
