import {CalendarEvent} from '../types/CalendarEvent';
import {CalendarUser} from '../types/CalendarUser';
import {CalendarData} from '../types/CalendarData';
import {EventSearchResult} from '../hooks/UseEventList';
import {getRokuyo} from '../../general/utils/Rokuyo';
import {format} from 'date-fns';
import {ja} from 'date-fns/locale';
import {CalendarUserGroup} from '../types/CalendarUserGroup';
import {UserTypes} from '../../auth/types/UserTypes';


export class MonthlyData {
    map: { [key: string]: CalendarEvent[] };
    days: {
        date: Date,
        weekName: string;
        rokuyo: string
    }[]
    users: CalendarUser[];
    groups: CalendarUserGroup[];

    constructor(calendarData: CalendarData, result: EventSearchResult) {
        this.map = {}
        this.days = [];
        this.users = [];
        this.groups = calendarData.groups;
        result.events.forEach((event) => {
            this.setEvent(event);
        })
        for (let cursor = new Date(result.start); cursor < result.end; cursor.setDate(cursor.getDate() + 1)) {
            const date = new Date(cursor.getTime());
            const rokuyo = getRokuyo(date);
            const weekName = format(date, 'E', {locale: ja});
            this.days.push({
                date,
                rokuyo,
                weekName
            })
        }

        calendarData.calendarUsers.forEach((calendarUser) => {
            this.users.push(calendarUser);
        })
    }

    createMapKey(date: Date, userId: number): string {
        return format(date, 'yyyyMMdd') + '-' + userId.toString();
    }

    getDays() {
        return this.days;
    }

    getEventUser() {
        return this.users.find((calendarUser) => {
            return (calendarUser.user.type === UserTypes.Event);
        })
    }

    getUsers(groupId: number | null = null) {

        if (groupId) {
            const targetGroup = this.groups.find((group) => {
                return group.id === groupId;
            })
            if (targetGroup) {
                return this.users.filter((calendarUser) => {
                    return (targetGroup.userIds.indexOf(calendarUser.user.id) !== -1);
                });
            } else {
                return [];
            }
        } else {
            return this.users;
        }
    }

    getEvents(date: Date, userId: number) {
        const mapKey = this.createMapKey(date, userId);
        if (this.map[mapKey]) {
            return this.map[mapKey];
        } else {
            return [];
        }
    }

    setEvent(event: CalendarEvent) {
        event.eventUsers.forEach((eventUser) => {
            for (let cursor: Date = new Date(event.startDate.getTime()); cursor <= event.endDate; cursor.setDate(cursor.getDate() + 1)) {
                // event.eventUsers.forEach((eventUser) => {
                const mapKey: string = this.createMapKey(cursor, eventUser.userId);
                if (!this.map[mapKey]) {
                    this.map[mapKey] = [];
                }
                this.map[mapKey].push(event);
                // })
            }
        })
    }
}

export type MonthlyDataDay = {
    date: Date;
    rokuyo: string;
    userEvents: {
        [key: number]: CalendarEvent[]
    }
}

