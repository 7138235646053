import {HashRouter, Route, Routes} from 'react-router-dom';
import Layout from './Layout';
import Error404 from './Error404';
import DashBoard from './DashBoard';
import CalendarPage from '../calendar/pages/CalendarPage';
import MonthlyCalendarPage from '../calendar/pages/MonthlyCalendarPage';
import DailyCalendarPage from '../calendar/pages/DailyCalendarPage';
import {DefaultLayout as CalendarDefaultLayout} from '../../features/calendar/layouts/DefaultLayout';
import Shift from '../shift/pages/Shift';
import ProfilePage from '../auth/pages/ProfilePage';


export default function RouterConfig() {

	return (
		<HashRouter>
			<Routes>
				<Route path="/" element={<Layout/>}>
					<Route index element={<DashBoard/>}/>
					<Route path="user/profile" element={<ProfilePage/>}/>
					{/*/!*	<Route index element={<Home/>}/>*!/*/}
					<Route path="calendar" element={<CalendarDefaultLayout/>}>
						<Route path=":calendarId" element={<CalendarPage/>}>
							<Route path="personal/monthly" element={<MonthlyCalendarPage/>}/>
							<Route path="personal/monthly/:year/:month" element={<MonthlyCalendarPage/>}/>
							<Route path="personal/daily" element={<DailyCalendarPage/>}/>
							<Route path="personal/daily/:year/:month/:day" element={<DailyCalendarPage/>}/>
						</Route>
					</Route>
					{/*/!*	<Route path="/calendar/monthly" element={<MonthlyLayout/>}>*!/*/}
					{/*/!*		<Route path=":year/:month" element={<MonthlyPage/>}/>*!/*/}
					{/*/!*	</Route>*!/*/}
					{/*/!*	<Route path="/calendar/daily" element={<DailyLayout/>}>*!/*/}
					{/*/!*		<Route path=":year/:month" element={<DailyPage/>}/>*!/*/}
					{/*/!*	</Route>*!/*/}
					<Route path="shift/:id" element={<Shift/>}/>
					<Route path="shift/:id/:year/:month/:day" element={<Shift/>}/>
					<Route path="*" element={<Error404/>}/>
				</Route>
			</Routes>
		</HashRouter>
	);
}


// export default function RouterConfig() {
// 	return (
// 		<AuthContext.Consumer>
// 			{({isAuthenticated, setAuthenticated}) => (
// 				<HashRouter>
// 					<Routes>
// 						<Route path="/" element={<DefaultLayout/>}>
// 							<Route index element={<Home/>}/>
// 							<Route path="/calendar/monthly" element={<MonthlyLayout/>}>
// 								<Route path=":year/:month" element={<MonthlyPage/>}/>
// 							</Route>
// 							<Route path="/calendar/daily" element={<DailyLayout/>}>
// 								<Route path=":year/:month" element={<DailyPage/>}/>
// 							</Route>
// 							<Route path="/shift/:id" element={<Shift/>}/>
// 							<Route path="/shift/:id/:year/:month/:day" element={<Shift/>}/>
// 						</Route>
// 					</Routes>
// 				</HashRouter>
// 			)}
// 		</AuthContext.Consumer>
// 	);
// }
