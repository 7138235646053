import * as React from 'react';
import List from '@mui/material/List';
import {useNavigate} from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import {Collapse} from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {useState} from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import ScheduleIcon from '@mui/icons-material/Schedule';
import usePermission from "../auth/hooks/usePermission";
import {PERMISSION} from "../auth/types/PERMISSION";

type SidebarProps = {
    onClose: () => void
}

export default function Sidebar(props: SidebarProps) {
    const [scheduleListOpened, setScheduleListOpened] = useState<boolean>(true)
    const {hasPermission} = usePermission();

    const navigate = useNavigate();
    const jump = (url: string) => {
        navigate(url);
        props.onClose();
    }
    return (
        <div>
            <List>
                <ListItem key={'dashboard'} disablePadding>
                    <ListItemButton onClick={() => {
                        jump('/')
                    }}>
                        <ListItemIcon>
                            <DashboardIcon/>
                        </ListItemIcon>
                        <ListItemText primary={'ダッシュボード'}/>
                    </ListItemButton>
                </ListItem>
                {hasPermission(PERMISSION.CalendarAllRead) &&
                    <>
                        <ListItem key={'schedule'} disablePadding>
                            <ListItemButton onClick={() => {
                                setScheduleListOpened(!scheduleListOpened)
                            }}>
                                <ListItemIcon>
                                    <CalendarMonthIcon/>
                                </ListItemIcon>
                                <ListItemText primary={'全体スケジュール'}/>
                                {scheduleListOpened ? <ExpandLess/> : <ExpandMore/>}
                            </ListItemButton>
                        </ListItem>
                        <Collapse in={scheduleListOpened} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemButton sx={{pl: 4}} onClick={() => {
                                    jump('/calendar/1/personal/daily')
                                }}>
                                    <ListItemIcon>
                                        <ScheduleIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="一日予定表"/>
                                </ListItemButton>
                                <ListItemButton sx={{pl: 4}} onClick={() => {
                                    jump('/calendar/1/personal/monthly')
                                }}>
                                    <ListItemIcon>
                                        <CalendarViewMonthIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="月間予定表"/>
                                </ListItemButton>
                            </List>
                        </Collapse>
                    </>
                }
                {hasPermission(PERMISSION.ShiftAllRead) &&
                    <>
                        <ListItem key={'shift'} disablePadding>
                            <ListItemButton onClick={() => {
                                jump('/shift/1')
                            }}>
                                <ListItemIcon>
                                    <DashboardIcon/>
                                </ListItemIcon>
                                <ListItemText primary={'社頭番表'}/>
                            </ListItemButton>
                        </ListItem>
                    </>
                }
            </List>

        </div>)
}
