export default function useLocalStorage() {

    const getLocalStorageValue = (key: string): string | null => {
        return localStorage.getItem(key);
    }
    const setLocalStorageValue = (key: string, value: string): void => {
        localStorage.setItem(key, value);
    }
    const removeLocalStorageValue = (key: string): void => {
        localStorage.removeItem(key);
    }
    return {getLocalStorageValue, setLocalStorageValue, removeLocalStorageValue};
}
