import {useParams} from 'react-router-dom';
import {EventSearchCriteria} from '../hooks/UseEventList';
import EventSearchProvider from '../contexts/EventSearchContext';

import {CalendarDataContext} from '../contexts/CalendarDataContext';
import {useContext, useMemo} from 'react';
import TableComponent from '../components/MonthlyCalendar/TableComponent';
import MonthlyCalendarSettingsProvider, {MonthlyCalendarSettings} from '../contexts/MonthlyCalendarSettingsContext';
import DirectLabelNavigation from '../components/MonthlyCalendar/DirectLabelNavigation';
import CalendarEventLogs from '../components/Calendar/CalendarEventLogs';

export default function MonthlyCalendarPage() {
    const params = useParams<any>();
    const [calendarData] = useContext(CalendarDataContext);

    const criteria = useMemo(() => {
        const today = new Date();
        const year = (params.year) ? parseInt(params.year, 10) : today.getFullYear();
        const month = (params.month) ? parseInt(params.month, 10) : (today.getMonth() + 1);
        const start = new Date(year, month - 1, 1, 0, 0, 0);
        const end = new Date(year, month - 1, 1, 0, 0, 0);
        end.setMonth(end.getMonth() + 1);
        end.setSeconds(-1);
        return {
            calendarId: calendarData.id,
            start,
            end
        }
    }, [params, calendarData]);


    const defaultSettings: MonthlyCalendarSettings = {
        directLabelMode: false,
        directLabelFlag: null,
        uploading: false,
        showCalendarEventLog: false
    }

    return (
        <EventSearchProvider criteria={criteria}>
            <MonthlyCalendarSettingsProvider defaultSettings={defaultSettings}>
                <TableComponent/>
                <DirectLabelNavigation/>
            </MonthlyCalendarSettingsProvider>
        </EventSearchProvider>
    )
}
