import React, {useContext, useRef, useState} from 'react';
import {CalendarUser} from '../../types/CalendarUser';
import {CalendarEvent} from '../../types/CalendarEvent';
import css from './DetailCell.module.scss'
import CellPopover from './CellPopover';
import {format} from 'date-fns';
import DateTimeRange from './DateTimeRange';


type UserCellProps = {
	calendarUser: CalendarUser,
	date: Date;
	events: CalendarEvent[]
}

export default function DetailCell(props: UserCellProps) {

	const [showDetail, setShowDetail] = useState<boolean>(false);
	const cellRef = useRef(null);
	const weekNumber = props.date.getDay();
	const onClickCell = () => {
		setShowDetail(true);
	}

	return (
		<>
			<td
				ref={cellRef}
				className={css.cell}
				data-week-number={weekNumber}
				data-active={(showDetail) ? 1 : 0}
				data-clickable="1"
				onClick={() => {
					onClickCell()
				}}>
				<div className={css.events}>
					{props.events.map((event) => {
						return (
							<div className={css.event} key={event.id}>
								<span className={css.subject}>{event.subject}</span>
								{!event.isAllDay &&
                <span className={css.range}><DateTimeRange
                    startDate={event.startDate} endDate={event.endDate} baseDate={props.date}/>
								</span>
								}
							</div>
						)
					})}
				</div>
			</td>
			{showDetail &&
      <CellPopover show={showDetail} anchorEl={cellRef.current} onClose={() => {
				setShowDetail(false)
			}} date={props.date} calendarUser={props.calendarUser} events={props.events}/>
			}
		</>


	)

}
