import React, {useContext} from 'react';
import {ShiftUser} from '../../types/ShiftUser';
import {format} from 'date-fns';
import {ShiftEditorContext} from '../../context/ShiftEditorContext';
import {ShiftDayUser} from '../../types/ShiftDayUser';
import {cloneDeep} from 'lodash';
import {ShiftDataContext} from '../../context/ShiftDataContext';
import {
	Checkbox,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	FormGroup, IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


export default function ShiftUserSelectionModal() {

	const {editor} = useContext(ShiftEditorContext);
	const {shiftData} = useContext(ShiftDataContext);

	const isUsed = (shiftDayUser: ShiftUser) => {
		const numMatch = editor.tmpShiftDayData.shiftDayUsers.filter((srcShiftDayUser) => {
			return (srcShiftDayUser.user.id === shiftDayUser.user.id);
		}).length;

		return (numMatch > 0)
	}

	const onClickShiftUser = (e: any, shiftUser: ShiftUser) => {
		if (isUsed(shiftUser)) {
			onRemoveShiftUser(shiftUser);
		} else {
			onAddShiftUser(shiftUser);
		}
		e.preventDefault();
	}

	const onRemoveShiftUser = (shiftDayUser: ShiftDayUser) => {
		const data = cloneDeep(editor.tmpShiftDayData);
		for (let i = 0; i < data.shiftDayUsers.length; i++) {
			if (data.shiftDayUsers[i].user.id === shiftDayUser.user.id) {
				data.shiftDayUsers.splice(i, 1);
				break;
			}
		}
		editor.setTmpShiftDayData(data);
	}
	const onAddShiftUser = (shiftUser: ShiftUser) => {
		const data = cloneDeep(editor.tmpShiftDayData);
		data.shiftDayUsers.push(shiftUser);
		editor.setTmpShiftDayData(data);
	}

	const renderMembers = () => {
		return (
			<FormGroup aria-label="position" row>
				{shiftData.shiftUsers.map((shiftUser) => {
					return (
						<FormControlLabel
							control={<Checkbox/>}
							checked={isUsed(shiftUser)}
							onClick={(e) => {
								onClickShiftUser(e, shiftUser)
							}}
							label={shiftUser.user.displayName}/>
					)
				})}
			</FormGroup>
		)
	}


	return (
		<Dialog
			fullScreen={false}
			open={true}
			fullWidth={true}
			maxWidth={'sm'}
			onClose={() => {
				editor.setShowShiftUserSelectionModal(false)
			}}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				<span>{format(editor.tmpShiftDayData.date, 'yyyy年M月d日')}の担当者</span>
				<IconButton
					aria-label="close"
					onClick={() => {
						editor.setShowShiftUserSelectionModal(false)
					}}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon/>
				</IconButton>
			</DialogTitle>
			<DialogContent>
				{renderMembers()}
			</DialogContent>
		</Dialog>
	)
}
